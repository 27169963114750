import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';

/**
 * Link component
 * @param {Object} [props]
 * @param {*} [props.children] - React component(s)
 * @param {string} [props.text] - Text in link
 * @param {Function} [props.onLinkClick]  - call function on link click
 * @param {'' | 'dark' | 'light'} [props.theme] - Theme
 * 
 * @component
 */
export default forwardRef(({ children = null, text = "", theme = '', onLinkClick = () => {}, pathname = "", ...resetProps }, ref) => (
  <div className="link-holder">
    <Link
      ref = {ref}
      className = {[
        'Link',
        theme === 'dark' ? 'dark' : '',
        theme === 'light' ? 'light' : '',
      ].join(' ')}
      onClick = {onLinkClick}
      to = {pathname}
      {...resetProps}
    >
        {text}
        {children}
    </Link>
  </div>

));
