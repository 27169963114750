import React from 'react';
import { Link } from 'react-router-dom';

/**
 * Icon for SideMenu component (for Container)
 * @param {Object} [props]
 * @param {string} [props.to] - Link address
 * @param {boolen} [props.selected] - Selected style
 * @param {string} [props.image] - Image url
 * @param {string} [props.imageWhenSelected] - Image url when icon is selected
 * @param {Function} [props.onClickEvent] - On click callback method
 * 
 * @component
 */
export default ({ to = '/', selected = false, image = '', imageWhenSelected = '', label = '', onClickEvent}) => (
  <Link to={to} onClick={onClickEvent}>
    <div className={`Container-SideMenu-Icon${selected ? ' selected' : ''}`}>
      {!!image && <img src={image} alt="Icon"/>}

      {!!label && <span>{label}</span>}
    </div>
    <div className={"Container-SideMenu-Icon-Mob"}>
      {!!image && <img src={!selected ? image : imageWhenSelected} alt="Icon"/>}
    </div>
  </Link>
);
