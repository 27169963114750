import access from './access';
import auth from './auth';
import business from './business';
import businessprofile from './businessprofile';
import contact from './contact';
import pass from './pass';
import user from './user';
import upload from './upload';
import voucher from './voucher';

export default {
  access,
  auth,
  business,
  businessprofile,
  contact,
  pass,
  user,
  upload,
  voucher
}

export {
  access,
  auth,
  business,
  businessprofile,
  contact,
  pass,
  user,
  upload,
  voucher
}
