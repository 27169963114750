import React from 'react';
import { useHistory } from 'react-router-dom';
import Page from '../../components/Page';
import Container from '../../components/Container';
import HeaderTitle from '../../components/HeaderTitle';
import Title from '../../components/Title';
import Paragraph from '../../components/Paragraph';
import Button from '../../components/Button';
import UserLibrary from '../../libraries/User';
import redirectConfig from '../../config/redirect';

/**
 * Home page (user loggedin)
 * @component
 */
export default () => {
  const history = useHistory();

  // Check if __REDIRECT_TO_GAMES__ flag is set, then redirect if it is ture
  if (window.__REDIRECT_TO_GAMES__) {
    window.location.replace(redirectConfig.games);

    return null;
  }

  return (
    <Page redirect>
      <HeaderTitle>MySCB</HeaderTitle>

      <Container>
        <Container.SideMenu />

        <Title>Willkommen!</Title>

        <Title small>
          {`${UserLibrary.get('firstName') || ''} ${UserLibrary.get('lastName') || ''}`}
        </Title>

        {/* <Paragraph>
          Dein MySCB-Konto ist nun erstellt. Hier kannst du deine Saisonkarte(n) verwalten und beim MySCB-Loyalitätsprogramm teilnehmen.
        </Paragraph> */}

        {/* TODO: Change route */}
        <Button onClick={() => history.push('/tickets')}>
          Jetzt Saisonabo(s) hinzufügen
        </Button>

        <Paragraph>
          {['Beste Grüsse und Hopp SCB!', 'Dein MySCB-Team']}
        </Paragraph>
      </Container>
    </Page>
  )
}
