import React from 'react';

/**
 * Invite component for SquarePasses
 * @param {Object} [props]
 * @param {Function} [props.onInviteClick] - Callback method when invite is clicked
 * @component
 */
export default ({ onInviteClick = () => {} }) => (
  <div className="SquarePasses-Item invite">
    <div className="SquarePasses-Item-body" onClick={onInviteClick}>
      <span>Einladen</span>
    </div>
  </div>
);
