import React from 'react';

/**
 * Error messages component
 * @param {Object} [props]
 * @param {string | [string]} [props.messages] - React component(s)
 * @component
 */
export default ({ messages }) => (
  <div className="ErrorMessages">
    {typeof messages === 'string'
      ? <p>{messages}</p>
      : messages.map(error => <p key={error}>{error}</p>)
    }
  </div>
);
