/**
 * Config for React Query
 * {@link https://react-query.tanstack.com/docs/api/#reactqueryconfigprovider}
 */
export default {
  queries: {
    retry: false,
    refetchOnWindowFocus: false,
  },
}
