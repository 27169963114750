import React from 'react';
import { FaUser, FaClock } from 'react-icons/fa';
import Title from '../Title';
import Paragraph from '../Paragraph';
import Time from '../../libraries/Time';

/**
 * Voucher info
 * @param {Object} props
 * @param {string} [props.name] - Voucher name
 * @param {string} [props.expireAt] - Expire date
 * @param {string} [props.description] - Description
 * @param {string} [props.image] - Image URL
 * @param {string} [props.firstName] - First name of voucher owner
 * @param {string} [props.lastName] - Last name of voucher owner
 * @param {Object} [props.style] - Styles for container
 * @component
 */
export default ({
  name = '',
  expireAt = '',
  description = '',
  image = '',
  firstName = '',
  lastName = '',
  style,
  children,
}) => (
  <div className="VoucherInfo" style={style}>
    {!!name && (
      <>
        <Title small>
          {name}

          <span className="info">
            {(!!firstName || !!lastName) && (
              <>
                <FaUser />

                {`${firstName} ${lastName}`}
              </>
            )}

            {!!expireAt && (
              <>
                <FaClock />

                {Time.date(expireAt)}
              </>
            )}
          </span> 
        </Title>
          {children}
      </>
    )}

    {!!description && (
      <Paragraph>
        {description}
      </Paragraph>
    )}

    {!!image && (
      <img
        className="image"
        src={image}
        alt="Voucher photo"
      />
    )}
  </div>
);
