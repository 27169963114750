import React from 'react';
import ReactModal from 'react-modal';
import Header from './Header';

/**
 * Styles for Modal component
 * @type {Object}
 */
const modalStyle = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 500,
    overflow: "scroll",
    padding: "40px 0"
  },
  content: {
    position: "unset",
    top: "unset",
    bottom: "unset",
    left: "unset",
    right: "unset",
    overflow: 'auto',
    width: "90%",
    maxWidth: "570px",
    margin: "auto",
    border: 0,
    borderRadius: 0,
  }
};

/**
 * Modal component
 * @param {Object} [props]
 * @param {string} [props.title] - Title text
 * @param {'small' | 'default' | 'big'} [props.size] - Modal width size
 * @param {*} [props.children] - React component(s)
 * {@link http://reactcommunity.org/react-modal/}
 * @component
 */
export default ({ title = '', size = 'default', children, ...restProps }) => {
  const style = { ...modalStyle };

  if (size === 'small') {
    style.content.width = '300px';
  } else if (size === 'big') {
    style.content.width = '800px';
    style.content.maxWidth = '800px';
  }

  return (
    <ReactModal
      ariaHideApp={false} // Fix for warning message
      style={style}
      contentLabel="Modal"
      {...restProps}
    >
      <Header
        title={title}
        onCloseClick={restProps.onRequestClose}
      />
      <div className="Modal-Body">
        {children}
      </div>
    </ReactModal>
  );
};
