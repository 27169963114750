import React, { Children } from 'react';
import Side from './Side';
import SideMenu from './SideMenu';

/**
 * Container component
 * @param {Object} [props]
 * @param {boolean} [props.nospace] - Remove top and bottom empty space
 * @param {*} [props.children] - React component(s)
 * @component
 */
const Container = ({ nospace = false, children }) => {
  // Remove all none Side component(s)
  const sideComponents = Children.map(children, child => child?.type?.container ? child : null);

  return (
    <div className={nospace ? 'Container nospace' : 'Container'}>
      {!!Children.count(sideComponents) && sideComponents}

      <div className="Container-body">
        {/* Remove Side component if there is any */}
        {Children.map(children, child => child?.type?.container ? null : child)}
      </div>
    </div>
  );
};

Container.Side = Side;
Container.SideMenu = SideMenu;

export default Container;
