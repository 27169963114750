import React, { useState, useEffect } from 'react';
import {
  useQuery,
  useMutation,
  queryCache,
} from 'react-query';
import Page from '../components/Page';
import Container from '../components/Container';
import HeaderTitle from '../components/HeaderTitle';
import Title from '../components/Title';
import ContactList from '../components/ContactList';
import UserForm from './../components/UserForm';
import Button from './../components/Button';
import Loader from './../components/Loader';
import ErrorMessages from './../components/ErrorMessages';
import api from '../api';
import PlusIcon from '../assets/images/icons/Plus.svg'
import Alert from '../modals/Alert';
import filterObjectLibrary from '../libraries/filterObject';
import requsetErrorMessage from '../libraries/requsetErrorMessage';

/**
 * Filter data from contact object
 * @param {Object} contact - Contact data
 */
const dataForServer = contact => filterObjectLibrary(contact, [
  'birthday',
  'city',
  'country',
  'first_name',
  'gender',
  'last_name',
  'phone',
  'street',
  'zip',
  'email',
]);

/**
 * Contacts page
 * @component
 */
export default () => {
  const [newContactOption, setNewContactOption] = useState(false);
  const [contactToUpdate, setContactToUpdate] = useState(null);
  const [alert, setAlert] = useState(false);
  const [hideSide, setHideSide] = useState(false);
  const [errorsNewContact, setErrorsNewContact] = useState(null);
  const [IsErrorsNewContact, setIsErrorsNewContact] = useState(null);
  const [errorsUpdateContact, setErrorsUpdateContact] = useState(null);
  const [IsErrorsUpdateContact, setIsErrorsUpdateContact] = useState(null);
  const { data, error, isLoading, isError } = useQuery('contacts', api.contact.contacts);
  const [createContact, { isLoading: isCreateLoading, error: createError }] = useMutation(api.contact.createContact, {
    onSuccess({ data }) {
      queryCache.setQueryData('contacts', queryCacheData => ({
        ...queryCacheData,
        data: [
          ...queryCacheData.data,
          data,
      ]}));

      setNewContactOption(false);
      setHideSide(false)
    }
  });
  const [updateContact, { isLoading: isUpdateLoading, error: updateError }] = useMutation(api.contact.updateContact, {
    onSuccess({ data }) {
      queryCache.setQueryData('contacts', queryCacheData => ({
        ...queryCacheData,
        data: [...queryCacheData.data.map(contact => contact.id === data.id ? data : contact)],
      }));

      setContactToUpdate(null);
      setHideSide(false)
    }
  });
  const [deleteContact, { isLoading: isDeleteLoading, error: deleteError }] = useMutation(api.contact.deleteContact, {
    onSuccess({ data }) {
      queryCache.setQueryData('contacts', queryCacheData => ({
        ...queryCacheData,
        data: [...queryCacheData.data.filter(contact => contact.id !== data.id)],
      }));
      setAlert(false)
      setContactToUpdate(null);
      setHideSide(false)
    },
    onError: () => {
      setAlert(false)
    }
  });

  // Reset errors on New Contact
  useEffect(() => {
    setIsErrorsNewContact(!!createError?.data?.message)
    setErrorsNewContact(!!createError?.data?.message ? createError.data.message : '')
  }, [createError]);
  useEffect(() => {
    setIsErrorsNewContact(null)
    setErrorsNewContact(null)
  }, [newContactOption]);

  // Reset errors on Update Contact
  useEffect(() => {
    setIsErrorsUpdateContact(!!deleteError?.data?.message || !!updateError?.data?.message)
    setErrorsUpdateContact(!!updateError?.data?.message ? updateError.data.message : (!!deleteError?.data?.message ? deleteError.data.message : ""))
  }, [updateError, deleteError]);
  useEffect(() => {
    setIsErrorsUpdateContact(null)
    setErrorsUpdateContact(null)
  }, [contactToUpdate]);

  const handleNewContactOptionClick = () => {
    setContactToUpdate(null);
    setHideSide(true)
    setNewContactOption(true);
  };

  const handleContactEditClick = contact => () => {
    setContactToUpdate(contact);
    setHideSide(true)
    setNewContactOption(false);
  };

  return (
    <Page redirect>
      <HeaderTitle>MySCB</HeaderTitle>

      <Container>
        <Container.SideMenu refreshSide={() => {
            setHideSide(false)
            setContactToUpdate(false);
            setNewContactOption(false);
          }}
        />

        <Container.Side hideOnlyOnMob={hideSide}>
          <Title
            small
            theme="side"
          >
            Kontakte
          </Title>

          <Button
            fullwidth
            margin="bottom"
            disabled={newContactOption}
            onClick={handleNewContactOptionClick}
            icon={{ src: PlusIcon, alt: 'icon' }}
          >
            Kontakt hinzufügen
          </Button>

          {isLoading ? <Loader style={{ marginBottom: 30 }} /> : !isError && (
            <ContactList>
              {data && data.data && data.data.map(contact => (
                <ContactList.Item
                  key={contact.id}
                  editDisabled={contactToUpdate ? contactToUpdate.id !== contact.id : false}
                  contactPerson={contact}
                  onEditClick={handleContactEditClick(contact)}
                />
              ))}
            </ContactList>
          )}
        </Container.Side>

        {!!error?.data?.message && <ErrorMessages messages={requsetErrorMessage(error.data.message)} />}

        {newContactOption &&
          <UserForm
            hasAddress={false}
            hasZip={false}
            hasCity={false}
            hasCountry={false}
            hasBirthday={false}
            hasEmail={false}
            mandatory={["phone"]}
            title="Kontakt hinzufügen"
            isLoading={isCreateLoading}
            confirmLabel="Kontakt hinzufügen"
            cancelLabel="Abbrechen"
            onConfirm={createContact}
            onCancel={() => {
              setNewContactOption(false)
              setHideSide(false)
            }}
            isError={IsErrorsNewContact}
            errorMsg={errorsNewContact}
          />
        }

        {!!contactToUpdate &&
          <>
            <UserForm
              hasAddress={false}
              hasZip={false}
              hasCity={false}
              hasCountry={false}
              hasBirthday={false}
              hasEmail={false}
              user={contactToUpdate}
              mandatory={["phone"]}
              title="Kontakt anpassen"
              isLoading={isUpdateLoading}
              confirmLabel="Änderungen speichern"
              cancelLabel="Kontakt entfernen"
              onConfirm={data => updateContact({ ...dataForServer(data), contact_id: data.id })}
              onCancel={(e) => {
                e.preventDefault()
                setAlert(true)}}
              isError={IsErrorsUpdateContact}
              errorMsg={errorsUpdateContact}
            />
            <div className="add-row">
              <Button
                theme="gray"
                disabled={isUpdateLoading}
                onClick={() => {
                  setContactToUpdate(null)
                  setHideSide(false)
                }}
              >
                Abbrechen
              </Button>
            </div>
          </>
        }
          <Alert
            isOpen={alert}
            title = "Kontakt entfernen"
            message = "Sind Sie sicher, dass Sie diesen Kontakt entfernen möchten?"
            positiveLabel = "Ja"
            negativeLabel = "Nein"
            onPositiveClick = {() => {
              deleteContact({ contact_id: contactToUpdate.id })
            }}
            onNegativeClick = {() => setAlert(false)}
            onRequestClose = {() => setAlert(false)}
            isLoading={isDeleteLoading}
          />
      </Container>
    </Page>
  );
}
