import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Title from '../Title';
import Input from '../Input';
import Button from '../Button';
import Select from '../Select';
import InputImage from '../InputImage';
import ErrorMessages from '../ErrorMessages';
import gender from '../../utils/select/gender';
import requsetErrorMessage from '../../libraries/requsetErrorMessage';
import emptyStringToNull from '../../libraries/emptyStringToNull';
import filterObjectLibrary from '../../libraries/filterObject';

/**
 * Filter data from user object
 * @param {Object} user - Contact data
 */
const dataForServer = user => filterObjectLibrary(user, [
  'id',
  'gender',
  'role',
  'first_name',
  'last_name',
  'phone_mobile',
  'phone',
  'email',
  'avatar',
  'user_email'
]);

/**
 * Business profile form component
 * @param {Object} [props]
 * @param {Object} [props.user] - Profile data
 * @param {string} [props.title] - Title text
 * @param {string} [props.confirmLabel] - Text in confirm button
 * @param {string} [props.cancelLabel] - Text in cancel button
 * @param {Function} [props.onConfirm] - Callback method when confirm button is clicked
 * @param {Function} [props.onCancel] - Callback method when cancel button is clicked
 * @param {boolen} [props.isError] - Error happened on request
 * @param {Array} [props.errorMsg] - Error messages on request returned with error
 * @param {boolen} [props.isLoading] - Spinner activ on button after request is sent
 * @param {boolen} [props.readonly] - Input field(s) is/are reado nly
 * @component
 */
export default ({
  user = {},
  title = '',
  confirmLabel = '',
  cancelLabel= '',
  onConfirm = () => {},
  onCancel = () => {},
  isError = false,
  errorMsg = null, // Initial value must be null
  isLoading = false,
  readonly = false,
}) => {
  const { register, handleSubmit, setValue, errors } = useForm({ criteriaMode: 'all' });
  const [userData, setUserData] = useState(dataForServer({
    ...user,
    gender: user.gender ? user.gender : gender[0].value,
    user_email: user.user?.email
  }));
  const [errorText, setErrorText] = useState(errorMsg);
  const [loading, setLoading] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => void(window.scrollTo(0, 0)), []);

  useEffect(() => void(setErrorText(errorMsg)), [errorMsg]);

  const handleChange = event => {
    setErrorText(null);

    setUserData({
      ...userData,
      [event.target.name]: event.target.value,
    });
  };

  const handleImageUpload = url => {
    setValue('avatar', url);

    setUserData({ ...userData, avatar: url });
  };

  const handleSuccessSubmit = data => onConfirm(emptyStringToNull({ ...userData, ...data }));

  return (
    <div className="form-container">
      {!!title && <Title>{title}</Title>}

      <form
        encType="multipart/form-data"
        onSubmit={handleSubmit(handleSuccessSubmit)}
      >
        <div className="row-wrap">
          <Select
            disabled={readonly}
            label="Anrede *"
            name="gender"
            defaultValue={user.gender}
            options={gender}
            onOptionChange={handleChange}
          />

          <Input
            ref={register}
            readOnly={readonly}
            label="Position"
            name="role"
            defaultValue={user.role}
            error={errors.position}
            onChange={handleChange}
          />

          <Input
            ref={register({ required: true })}
            readOnly={readonly}
            label="Vorname *"
            name="first_name"
            defaultValue={user.first_name}
            error={errors.first_name}
            onChange={handleChange}
          />

          <Input
            ref={register({ required: true })}
            readOnly={readonly}
            label="Nachname *"
            name="last_name"
            defaultValue={user.last_name}
            error={errors.last_name}
            onChange={handleChange}
          />

          <Input
            ref={register}
            readOnly={readonly}
            label="Handynummer"
            type="phone"
            name="phone_mobile"
            placeholder="Beispiel: +41791234567"
            defaultValue={user.phone_mobile}
            error={errors.phone_mobile}
            onChange={handleChange}
          />

          <Input
            ref={register}
            readOnly={readonly}
            label="Telefon"
            type="phone"
            name="phone"
            placeholder="Beispiel: +41791234567"
            defaultValue={user.phone}
            error={errors.phone}
            onChange={handleChange}
          />

          <Input
            ref={register}
            readOnly={readonly}
            label="E-Mail Firma"
            type="email"
            name="email"
            defaultValue={user.email}
            error={errors.email}
            onChange={handleChange}
          />

          <Input
            ref={register}
            readOnly={readonly}
            label="E-Mail privater MySCB-Account"
            type="email"
            name="user_email"
            defaultValue={user.user?.email}
            error={errors.email}
            onChange={handleChange}
          />

          <InputImage
            fullwidth
            ref={register}
            label="Benutzerbild"
            defaultValue={user.avatar}
            name="avatar"
            onChange={handleChange}
            onImageUpload={handleImageUpload}
            onLoading={setLoading}
          />
        </div>

        {isError && <ErrorMessages messages={requsetErrorMessage(errorText)} />}

        <div className={!!confirmLabel && !!cancelLabel ? "row" : ""}>
          {!!confirmLabel && (
            <Button
              fullwidth={!cancelLabel}
              type="submit"
              loading={isLoading}
              disabled={isLoading || loading}
            >
              {confirmLabel}
            </Button>
          )}

          {!!cancelLabel && (
            <Button disabled={isLoading} onClick={onCancel} theme="gray">
              {cancelLabel}
            </Button>
          )}
        </div>
      </form>
    </div>
  );
};
