import React from 'react';

/**
 * Title
 * @param {Object} props
 * @param {string} [props.subtitle] - Subtitle text
 * @param {boolen} [props.small] - Smaller title
 * @param {'' | 'side'} [props.theme] - Theme style
 * @param {string} props.children - Title text
 * @component
 */
export default ({ subtitle = '', small = false, theme = '', children }) => (
  <div className={[
    'Title',
    small ? 'small' : '',
    theme === 'side' ? 'side' : '',
    theme === 'light' ? 'light' : '',
  ].join(' ')}>
    <h1 className="Title-title">{children}</h1>

    {!!subtitle && <h2 className="Title-subtitle">{subtitle}</h2>}
  </div>
);
