import React from 'react';
import Container from '../Container';
import HeaderTitleImg from './../../assets/images/backgrounds/header-title.jpg';
import HeaderTitleImg2 from './../../assets/images/backgrounds/header-title-2.jpg';

/**
 * Header title
 * @param {Object} props
 * @param {string} props.children - Title text
 * @param {boolean} [props.isOnNoPage] - Is Header title for NoPage component
 * 
 * @component
 */
export default ({ children, isOnNoPage = false }) => (
  <div className="HeaderTitle" style = {{
    backgroundImage: !isOnNoPage ? `url(${HeaderTitleImg})` : `url(${HeaderTitleImg2})`, 
    backgroundPosition: !isOnNoPage ? "bottom" : 0 
    }}>
    <Container nospace>
      {typeof children === 'string' && <h1 style={{fontSize: 80}}>{children}</h1>}
    </Container>
  </div>
);
