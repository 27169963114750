import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import Paragraph from "./../Paragraph";
import Title from "./../Title";
import Input from "./../Input";
import Button from "./../Button";
import Select from "./../Select";
import Checkbox from "./../Checkbox";
import DatePicker from "./../DatePicker";
import ErrorMessages from './../ErrorMessages';
import DataProtection from "../../modals/DataProtection";
import countries from "../../utils/select/countries";
import gender from "../../utils/select/gender";
import requsetErrorMessage from '../../libraries/requsetErrorMessage';
import emptyStringToNull from '../../libraries/emptyStringToNull';

/**
 * User form modal component
 * @param {Object} [props]
 * @param {Object} [props.user] - User object
 * @param {string} [props.title] - Title text
 * @param {string} [props.paragraph] - Paragraph text
 * @param {boolen} [props.hasPassword] - Form has password
 * @param {boolen} [props.hasGender] - Form has gender selection
 * @param {boolen} [props.hasMarketing] - Form has marketing option
 * @param {boolen} [props.hasEmail] - Form has email option
 * @param {string} [props.confirmLabel] - Text in confirm button
 * @param {string} [props.cancelLabel] - Text in cancel button
 * @param {Function} [props.onConfirm] - Callback method when confirm button is clicked
 * @param {Function} [props.onCancel] - Callback method when cancel button is clicked
 * @param {boolen} [props.isError] - Error happened on request
 * @param {Array} [props.errorMsg] - Error messages on request returned with error
 * @param {boolen} [props.isLoading] - Spinner activ on button after request is sent
 * @param {'' | 'light'} [props.theme] - Theme
 * @param {[string]} [props.mandatory] - A list of fields that are mandatory
 * @param {[string]} [props.privateFields] - Filed(s) from this list will be visible different
 * @param {boolen} [props.phoneDisabled] - Is input for phone number disabled
 * @param {boolen} [props.hasDataProtectionConfirmation] - Form has data protecion confirmation
 * @param {boolen} [props.disabledScrollToTop] - Don't scroll to top
 * @param {boolen} [props.hasPassAssigningCheckbox] - checkbox on form for assigning ticket
 * 
 * @component
 */
export default ({
  user = {},
  title = '',
  paragraph = "",
  hasPassword = false,
  hasGender = false,
  hasFanname = false,
  hasMarketing = false,
  hasEmail = false,
  hasRepeatEmail = false,
  hasDataProtectionConfirmation = false,
  hasAddress = true,
  hasZip = true,
  hasCity = true,
  hasCountry = true,
  hasBirthday = true,
  confirmLabel = "",
  cancelLabel= "",
  onConfirm = () => {},
  onCancel = () => {},
  isError = false,
  errorMsg = null, //initial value must be null
  isLoading = false,
  theme = "",
  mandatory = [],
  privateFields = [],
  phoneDisabled = false,
  disabledScrollToTop = false,
  hasPassAssigningCheckbox = false
}) => {
  const [birthDate, setBirthDate] = useState(user.birthday ? (new Date(user.birthday)).toISOString() : '');
  const { register, handleSubmit, errors } = useForm({ criteriaMode: 'all' });
  const [localErrorMsg, setLocalError] = useState("");
  const [modalDataProtection, setModalDataProtection] = useState(false);

  const [userData, setUserData] = useState({
    ...user,
    ...hasGender ? { gender: (user && user.gender ? user.gender : gender[0].value) } : {},
    ...birthDate ? { birthday: birthDate } : {},
    country: user && user.country ? user.country : 'Schweiz',
  });
  const [errorText, setErrorText] = useState([])

  useEffect(() => { setErrorText(errorMsg) }, [errorMsg]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { !disabledScrollToTop && window.scrollTo(0, 0) }, []);

  const isPrivateField = field => privateFields.indexOf(field) >= 0;

  const handleChange = event => {
    setErrorText(null)
    setLocalError('');
    if(event.target.type === "checkbox") {
      setUserData({
        ...userData,
        [event.target.name]: event.target.checked,
      });
    } else {
      setUserData({
        ...userData,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleBirthdayChange = date => {
    setBirthDate(date);

    setUserData({
      ...userData,
      birthday: date,
    });
  };

  const handleSuccessSubmit = () => {
    setLocalError('');

    let userObj = emptyStringToNull(userData);

    if(hasRepeatEmail && userObj.email !== userObj.email_repeat) {
      setLocalError("Das E Mail-Adresse muss genau wiederholt werden.");

      return;
    }

    if(hasRepeatEmail) {
      delete userObj.email_repeat;
    }

    onConfirm(userObj)
  }

  return (
    <div className="form-container">
      {!!title && <Title>{title}</Title>}

      {!!paragraph && <Paragraph>{paragraph}</Paragraph>}

      <form onSubmit={handleSubmit(handleSuccessSubmit)}>
        {hasGender && <div className="row">
          <Select
            label={`Anrede ${mandatory.find((field) => field === "gender") ? "*" : ""}`}
            name="gender"
            defaultValue={user.gender}
            options={gender}
            onOptionChange={handleChange}
            marked={isPrivateField('gender')}
            theme={theme}
          />
        </div>}
        <div className="row-wrap">
          <Input
            label={`Vorname ${mandatory.find((field) => field === "first_name") ? "*" : ""}`}
            name="first_name"
            defaultValue={user.first_name}
            error={errors.first_name}
            ref={mandatory.find((field) => field === "first_name") && register({required: true})}
            onChange={handleChange}
            marked={isPrivateField('first_name')}
            theme={theme}
          />
          <Input
            label={`Nachname ${mandatory.find((field) => field === "last_name") ? "*" : ""}`}
            name="last_name"
            defaultValue={user.last_name}
            error={errors.last_name}
            ref={mandatory.find((field) => field === "last_name") && register({required: true})}
            onChange={handleChange}
            marked={isPrivateField('last_name')}
            theme={theme}
          />
          {hasFanname && (
            <Input
              label={`Fan name ${mandatory.find((field) => field === "fan_name") ? "*" : ""}`}
              name="fan_name"
              defaultValue={user.fan_name}
              error={errors.fan_name}
              ref={mandatory.find((field) => field === "fan_name") && register({required: true})}
              onChange={handleChange}
              marked={isPrivateField('fan_name')}
              theme={theme}
            />
          )}
          {hasAddress && <Input
            label={`Strasse & Nr ${mandatory.find((field) => field === "street") ? "*" : ""}`}
            name="street"
            defaultValue={user.street}
            error={errors.street}
            ref={mandatory.find((field) => field === "street") && register({required: true})}
            onChange={handleChange}
            marked={isPrivateField('street')}
            theme={theme}
          />}
          {hasZip && <Input
            label={`PLZ ${mandatory.find((field) => field === "zip") ? "*" : ""}`}
            name="zip"
            defaultValue={user.zip}
            error={errors.zip}
            ref={mandatory.find((field) => field === "zip") && register({required: true})}
            onChange={handleChange}
            marked={isPrivateField('zip')}
            theme={theme}
          />}
          {hasCity && <Input
            label={`Ort ${mandatory.find((field) => field === "city") ? "*" : ""}`}
            name="city"
            defaultValue={user.city}
            error={errors.city}
            ref={mandatory.find((field) => field === "city") && register({required: true})}
            onChange={handleChange}
            marked={isPrivateField('city')}
            theme={theme}
          />}
          {hasCountry && <Select
            label={`Land ${mandatory.find((field) => field === "country") ? "*" : ""}`}
            name="country"
            labelInOption=""
            defaultValue={user.country ? user.country : "Schweiz"}
            options={countries}
            onOptionChange={handleChange}
            marked={isPrivateField('country')}
            theme={theme}
          />}
          {hasBirthday && <DatePicker
            marked={isPrivateField('birthday')}
            theme={theme}
            label={`Geburtsdatum ${mandatory.find((field) => field === "birthday") ? "*" : ""}`}
            valueDate={birthDate}
            onChangeDate={handleBirthdayChange}
            name="date"
            error={errors.date}
            ref={mandatory.find((field) => field === "birthday") && register({required: true})}
          />}

          <Input
            label={`Handynummer ${mandatory.find((field) => field === "phone") ? "*" : ""}`}
            type="phone"
            name="phone"
            placeholder="Beispiel: +41791234567"
            defaultValue={user.phone}
            disabled = {phoneDisabled}
            error={errors.phone}
            ref={mandatory.find((field) => field === "phone") && register({required: true})}
            onChange={handleChange}
            marked={isPrivateField('phone')}
            theme={theme}
          />
          {/* {hasPassAssigningCheckbox && <div className="row">
            <Checkbox
                text="Für alle weiteren Einzeltickets zuteilen"
                name="assign_pass"
                theme={theme}
                ref={register({required: false})}
                onChange={handleChange}
            />
          </div>} */}
          <div className="row">
            {hasEmail && <Input
              label={`Email-Adresse ${mandatory.find((field) => field === "email") ? "*" : ""}`}
              type="email"
              name="email"
              defaultValue={user.email}
              error={errors.email}
              ref={mandatory.find((field) => field === "email") && register({required: true})}
              onChange={handleChange}
              marked={isPrivateField('email')}
              theme={theme}
            />}
            {hasRepeatEmail && <Input
              label={`Email-Adresse bestätigen ${mandatory.find((field) => field === "email_repeat") ? "*" : ""}`}
              type="email"
              name="email_repeat"
              error={errors.email_repeat}
              ref={mandatory.find((field) => field === "email_repeat") && register({required: true})}
              onChange={handleChange}
              marked={isPrivateField('email_repeat')}
              theme={theme}
            />}
          </div>
          {hasPassword && <Input
            label={`Passwort ${mandatory.find((field) => field === "password") ? "*" : ""}`}
            name="password"
            ispassword
            error={errors.password}
            ref={mandatory.find((field) => field === "password") && register({required: true})}
            onChange={handleChange}
            marked={isPrivateField('password')}
            theme={theme}
          /> }
        </div>
        {hasDataProtectionConfirmation && <div className="row">
          <Checkbox
            text="Ich stimme der Verarbeitung meiner Daten gemäss den "
            linkText="SCB-Datenschutzbestimmungen"
            textAfter="zu.*"
            onLinkClick={() => setModalDataProtection(true)}
            name="data_protection"
            error={errors.data_protection}
            ref={mandatory.find((field) => field === "data_protection") && register({required: true})}
            onChange={handleChange}
          />
        </div>}
        {hasMarketing && <div className="row">
          <Checkbox
            text="Mein Herz brennt für den SCB, deshalb möchte ich keine News und Goodies verpassen."
            name="accept_marketing"
            onChange={handleChange}
            initialValue = {user.accept_marketing}
          />
        </div>}
        {isError && <ErrorMessages messages={requsetErrorMessage(errorText)} />}
        {localErrorMsg && <ErrorMessages messages={localErrorMsg} />}
        <div className="row">
          {confirmLabel &&
            <Button
              type="submit"
              loading={isLoading}
              disabled={isLoading}
            >
            {confirmLabel}
          </Button>}
          {cancelLabel &&
            <Button
              disabled={isLoading}
              onClick={onCancel}
              theme="gray"
            >
            {cancelLabel}
          </Button>}
        </div>
      </form>
      <DataProtection
        isOpen={modalDataProtection}
        onRequestClose={() => setModalDataProtection(false)}
      />
    </div>
  );
};