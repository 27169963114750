import React from 'react';

/**
 * Loader component
 * @param {Object} [props]
 * @param {'' | 'big'} [props.size] - Loader size
 * @param {'' | 'dark'} [props.theme] - Theme
 * @component
 */
export default ({ size = '', theme = '', ...restProps }) => (
  <div
    className={[
      'Loader',
      size === 'big' ? 'big' : '',
      theme === 'dark' ? 'dark' : '',
    ].join(' ')}
    {...restProps}
  />
);
