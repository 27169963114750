import React from 'react';

/**
 * Row component for SquarePasses
 * @param {Object} [props]
 * @param {*} [props.children] - React component(s)
 * @component
 */
export default ({ children }) => (
  <div className="SquarePasses-Row">
    {children}
  </div>
);
