import API from '../libraries/API';

/**
 * Login request
 * @param {Object} params
 * @param {string} params.email - Email
 * @param {string} params.password - Password
 */
const login = params => API.post('auth/login', params);

/**
 * Logout request
 */
const logout = () => API.post('auth/logout');

/**
 * Register (signup) request
 * @param {Object} params
 * @param {string} params.password - Password
 * @param {string} params.email - Email address
 * @param {string} params.first_name - First name
 * @param {string} params.last_name - Last name
 * @param {string} params.street - Street address
 * @param {string} params.zip - ZIP code
 * @param {string} params.city - City name
 * @param {string} params.country - Country name
 * @param {string} params.phone - Phone number
 * @param {string} params.birthday - Date of birth
 * @param {string} params.gender - Gender
 */
const register = params => API.post('auth/register', params);

/**
 * Forgot password request
 * @param {Object} params
 * @param {string} params.email - Email address
 */
const forgotPassword = ({ email }) => API.post('auth/forgot-password', { email });

/**
 * Forgot password request
 * @param {Object} params
 * @param {string} params.code - Code
 * @param {string} params.newPassword - Code
 */
const resetPassword = params => API.post('auth/reset-password', params);

/**
 * Login with Facebook
 * @param {Object} params
 * @param {string} params.accessToken - Access token from Facebook
 */
const loginWithFacebook = ({accessToken, ...body}) => API.post(`auth/login/facebook?access_token=${accessToken}`, body);

export default {
  login,
  logout,
  register,
  forgotPassword,
  resetPassword,
  loginWithFacebook,
}
