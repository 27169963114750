import React, {
  useState,
  useEffect,
} from 'react';
import { Link } from 'react-router-dom';
import { FaTimes, FaChevronRight } from 'react-icons/fa';
import Map from './Map';
import TicketChecked from './TicketChecked';
import TimeLibrary from '../../libraries/Time';
import EditIcon from '../../assets/images/eticket/edit.png';

/**
 * Modal component
 * @param {Object} [props]
 * @param {Object} [props.guest] - Guest data
 * @param {Function} [props.guestForm] - Callback method for guest from
 * @param {boolean} [props.ticketChecked] - Is ticket checked
 * @component
 */
export default ({ guest = {}, guestForm = () => {}, ticketChecked = false, isTicketClosable = false }) => {
  const [showMap, setShowMap] = useState(false);

  useEffect(() => void(document.querySelector('html').style.overflow = showMap ? 'hidden' : 'auto'), [showMap]);

  return (
    <div className="ticket-view">
      {isTicketClosable &&
        <Link className='close-button' to='/tickets'>
          <FaTimes />
        </Link>
      }

      {ticketChecked ? (
        <TicketChecked guest={guest} />
      ) : (
        <div className="no-info">
          <p>Es fehlen Angaben zum Besucher!</p>

          {!!guest.game.date && guest.gameHasEnded && <p>Dieses Spiel ist schon zu Ende</p>}

          {!guest.game.date && <p>Spiel wurde verschoben - Neuer Termin ausstehend</p>}

          {!!guest.game.date && (
            <button onClick={guestForm} disabled={guest.gameHasEnded} style={{ opacity: guest.gameHasEnded ? 0.5 : 1 }}>
              <img src={EditIcon} alt="edit icon"/>

              <span>DATEN ANPASSEN</span>
            </button>
          )}
        </div>
      )}

      <div className="ticket-details">
        {guest.pass_type && (
          <div className="transport-info">
            <h3>{guest.pass_type}</h3>
          </div>
        )}

        <div className="seat-details">
          {!!guest?.seat?.seat_group?.sector && (
            <>
              <h3>Eingang {guest.seat.seat_group.sector.title}</h3>

              <p>Block {guest.seat.seat_group.title}</p>

              {guest.seat.row && <p>Reihe {guest.seat.row}</p>}

              {guest.seat.num && <p>Sitz {guest.seat.num}</p>}

              {/* <div className="map-btn-row">
                <button onClick={() => setShowMap(true)}>
                  <img src={MapIcon} alt="map icon" />

                  <FaAngleRight/>
                </button>
              </div> */}
            </>
          )}
        </div>

        <div className="game-details">
          {!!guest?.game?.away_team && (
            <>
              <h3>{guest.game.home_team.title} - {guest.game.away_team.title}</h3>

              <p>
                {!!guest.game.date ? (
                  <>
                    <span>{TimeLibrary.date(guest.game.date)}, </span>

                    <span>{TimeLibrary.time(guest.game.date)}</span>
                  </>
                ) : <span><em>Nachholtermin noch unbekannt</em></span>}
              </p>

              <p>PostFinance Arena</p>

              <p>Türöffnung: 1h45 vor dem Spiel</p>
            </>
          )}
        </div>

        <div className="transfer-alert">
          <h3>
            Ticket-Weitergabe
          </h3>

          <p>
            Bitte beachte, dass auch weitergegebene Tickets nur für eine Person gültig sind.

            <br /><br />

            Um weitere Personen für den Matchbesuch zu berechtigen, können Einzeltickets gekauft werden.
          </p>

          <a href="https://www.ticket-onlineshop.com/ols/scb/de/meisterschaft/channel/shop/index" rel="transfer ticket">
            Zu den einzeltickets

            <FaChevronRight />
          </a>
        </div>

        <div className="transport-info">
          <h3>QR-Code auch für BernMobil</h3>

          <p>Hin- und Rückfahrt in den Libero-Zonen 100 & 101 zur PostFinance-Arena sind ab drei Stunden vor Matchbeginn und bis zu zwei Stunden nach Spielschluss (neu inkl. Nachtbusse) im Matchticket inbegriffen.</p>
        </div>
      </div>

      {showMap && <Map close={() => setShowMap(false)}/>}
    </div>
  )
};
