import API from '../libraries/API';

/**
 * Business's data (loggedin user, my data)
 */
const data = () => API.get('user/me/business-partner');

/**
 * Update business's data (loggedin user, my data)
 * @param {Object} params
 * @param {string} params.logo - URL of image file
 * @param {string} params.name - Comapny name
 * @param {string} params.address - Street address
 * @param {string} params.location - Localtion
 * @param {string} params.zip - ZIP code
 * @param {string} params.postbox - Postbox number
 */
const update = params => API.patch('user/me/business-partner', params);

export default {
  data,
  update,
}
