import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import Title from "./../Title";
import Input from "./../Input";
import Button from "./../Button";
import ErrorMessages from './../ErrorMessages';
import requsetErrorMessage from '../../libraries/requsetErrorMessage';

/**
 * Modal component
 * @param {Object} [props]
 * @param {string} [props.title] - Title text
 * @param {string} [props.confirmLabel] - Text in confirm button
 * @param {string} [props.cancelLabel] - Text in cancel button
 * @param {Function} [props.onConfirm] - Callback method when confirm button is clicked
 * @param {Function} [props.onCancel] - Callback method when cancel button is clicked
 * @param {boolen} [props.isError] - Error happened on request
 * @param {Array} [props.errorMsg] - Error messages on request returned with error
 * @param {boolen} [props.isLoading] - Spinner activ on button after request is sent
 * @param {boolen} [props.hasCurrentPassword] - Form has current password
 * @component
 */

export default ({
  title = '',
  confirmLabel = "",
  cancelLabel= "",
  onConfirm = () => {},
  onCancel = () => {},
  isError = false,
  errorMsg = null,
  isLoading = false,
  hasCurrentPassword = false
}) => {

  const { register, handleSubmit, errors } = useForm({criteriaMode: 'all'});
  const [passwordData, setPasswordData] = useState({old_password: "",  new_password: "", repeatPassword: ""});
  const [localErrorMsg, setLocalError] = useState("");
  const [errorText, setErrorText] = useState([])
  useEffect(() => {
    setErrorText(errorMsg)
  }, [errorMsg])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleChange = (e) => {
    setErrorText(null)
    setLocalError('')
    setPasswordData({
      ...passwordData,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmitHandler = () => {
    setLocalError('');

    if(passwordData.new_password !== passwordData.repeatPassword) {
      setLocalError("Das Passwort muss genau wiederholt werden");

      return;
    }

    let passwordObj = { ...passwordData };

    if(hasCurrentPassword) {
      delete passwordObj.repeatPassword;
    } else {
      delete passwordObj.repeatPassword;
      delete passwordObj.old_password;
    }

    onConfirm(passwordObj);
  }

  return (
    <div className="form-container">
      {title && <Title>{title}</Title>}
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        {hasCurrentPassword && <div className='row'>
          <Input
            label="Altes Passwort"
            name="old_password"
            ispassword
            error={errors.old_password}
            ref={register({required: true})}
            onChange={handleChange}
          />
        </div>}
        <div className='row'>
          <Input
            label="Neues Passwort"
            name="new_password"
            ispassword
            error={errors.new_password}
            ref={register({required: true})}
            onChange={handleChange}
          />
          <Input
            label="Neues Passwort bestätigen"
            name="repeatPassword"
            ispassword
            error={errors.repeatPassword}
            ref={register({required: true})}
            onChange={handleChange}
          />
        </div>
        {isError && <ErrorMessages messages={requsetErrorMessage(errorText)} />}
        {localErrorMsg && <ErrorMessages messages={localErrorMsg} />}

        <div className="row">
          {confirmLabel &&
            <Button
              type="submit"
              loading={isLoading}
              disabled={isLoading}
            >
            {confirmLabel}
          </Button>}
          {cancelLabel &&
            <Button
              disabled={isLoading}
              onClick={onCancel}
              theme = "gray"
            >
            {cancelLabel}
          </Button>}
        </div>
      </form>
    </div>
  );
};