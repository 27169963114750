import React from "react";
import { useHistory } from "react-router-dom";
import Page from './../components/Page';
import Container from './../components/Container';
import HeaderTitle from './../components/HeaderTitle';
import Title from './../components/Title';
import Paragraph from './../components/Paragraph';
import Button from './../components/Button';

export default () => {
  const history = useHistory();

  return (
    <Page>
      <HeaderTitle isOnNoPage>
        #404
      </HeaderTitle>

      <Container>
        <div style={{ maxWidth: 400 }}>
          <Title>
            Der Klick ging daneben.
          </Title>

          <Paragraph>
            Diese Seite existiert nicht. Auf unserer Website erwarten dich aber jede Menge Volltreffer:
          </Paragraph>

          <Button
            onClick={() =>  history.push('/')}
          >
            BACK HOME
          </Button>
        </div>
      </Container>
    </Page>
  )
};
