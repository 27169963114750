import React, {
  useState,
  useEffect,
} from 'react';
import {
  Redirect,
  useLocation,
} from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import Page from './../components/Page';
import Container from './../components/Container';
import HeaderTitle from './../components/HeaderTitle';
import Title from './../components/Title';
import Input from '../components/Input';
import Button from '../components/Button';
import ChangePassword from './../components/ChangePassword'
import Link from './../components/Link';
import Paragraph from './../components/Paragraph';
import ErrorMessages from '../components/ErrorMessages';
import UserLibrary from '../libraries/User';
import api from '../api';
import requsetErrorMessage from '../libraries/requsetErrorMessage';

/**
 * Reset password page
 * @component
 */
export default () => {
  const [email, setEmail] = useState('');
  const [resetCode, setResetCode] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const location = useLocation();
  const { register, handleSubmit, errors } = useForm({ criteriaMode: 'all' });
  const [forgotPassword, { error, isLoading }] = useMutation(api.auth.forgotPassword, { onSuccess: () => setShowMessage(true) });
  const [resetPassword, { error: rpError, isLoading: isRPLoading }] = useMutation(api.auth.resetPassword, { onSuccess: () => setShowMessage(true) });

  useEffect(() => {
    setResetCode(location.search.indexOf('code=') >= 0
      ? (new URL(document.location)).searchParams.get('code')
      : null
    );
  }, [location]);

  // If user is logged in, redirect to profile page
  // if (UserLibrary.isLoggedIn()) {
  //   return <Redirect to="/profile" />;
  // }

  return (
    <Page>
      <HeaderTitle>MySCB</HeaderTitle>

      <Container>
        {!resetCode ? (
          <div className="form-container">
            <Title>Passwort vergessen?</Title>

            {showMessage ? (
              // TODO: Replace meesage here with some success message
              <Paragraph>
                Wir haben dir soeben eine Email geschickt mit einem Link, um dein Passwort zurückzusetzen. Schaue in deine Inbox und klicke auf den Aktivierungslink, um ein neues Passwort zu erstellen.
              </Paragraph>
            ) : (
              <>
                <form onSubmit={handleSubmit(() => forgotPassword({ email }))}>
                  <Input
                    label="Email-Adresse"
                    type="email"
                    name="email"
                    onChange={event => setEmail(event.target.value)}
                    error={errors.email}
                    ref={register({ required: true })}
                  />

                  {error?.data?.message && <ErrorMessages messages={requsetErrorMessage(error.data.message)} />}
                  <div className="row">
                    <Button
                      type="submit"
                      loading={isLoading}
                      disabled={isLoading}
                    >
                      Passwort zurücksetzen
                    </Button>
                  </div>
                </form>

                <Link
                  text="Zurück?"
                  theme="dark"
                  pathname={"/login"}
                />
              </>
            )}
          </div>
        ): (
          <>
            {showMessage ? (
              <>
                <Paragraph>
                  Dein Passwort wurde zurückgesetzt. Du kannst Dich nun mit Deinem neuen Passwort wieder einloggen.
                </Paragraph>
                <Link
                  text="Zum Login"
                  theme="dark"
                  pathname={"/login"}
                />
              </>
            ) : (
              <ChangePassword
                title="Passwort zurücksetzen"
                confirmLabel="Bestätigen"
                isLoading={isRPLoading}
                isError={!!rpError?.data?.message}
                errorMsg={rpError?.data?.message}
                onConfirm={({ new_password }) => resetPassword({ new_password, code: resetCode })}
              />
            )}
          </>
        )}
      </Container>
    </Page>
  )
}
