import React from 'react';
import { useLocation } from 'react-router-dom';
import Icon from './Icon';
import UserLibrary from '../../../libraries/User';
import profile from '../../../assets/images/sidemenu-icons/Profile.svg';
import home from '../../../assets/images/sidemenu-icons/Home_white.svg';
import tickets from '../../../assets/images/sidemenu-icons/Ticket_white.svg';
import contacts from '../../../assets/images/sidemenu-icons/Contacts_white.svg';
import business from '../../../assets/images/sidemenu-icons/Business_white.svg';
import logout from '../../../assets/images/sidemenu-icons/Logout.svg';
import profileSelected from '../../../assets/images/sidemenu-icons/user-selected.png';
import homeSelected from '../../../assets/images/sidemenu-icons/home-selected.png';
import ticketsSelected from '../../../assets/images/sidemenu-icons/tickets-selected.png';
import contactsSelected from '../../../assets/images/sidemenu-icons/contacts-selected.png';
import businessSelected from '../../../assets/images/sidemenu-icons/business-selected.png';
import logoutSelected from '../../../assets/images/sidemenu-icons/logout-selected.png';

/**
 * SideMenu component for Container
 * @param {Function} [props.refreshSide] - Refresh Side callback method
 *
 * @component
 */
export default ({ refreshSide }) => {
  const { pathname } = useLocation();

  return (
    <div className="Container-SideMenu">
      <Icon
        image={home}
        imageWhenSelected={homeSelected}
        to="/"
        label="Home"
        selected={pathname === '/'}
      />

      <Icon
        image={profile}
        imageWhenSelected={profileSelected}
        to="/profile"
        label="Profil"
        selected={pathname === '/profile'}
        onClickEvent={refreshSide}
      />

      <Icon
        to="/tickets"
        image={tickets}
        imageWhenSelected={ticketsSelected}
        label="Abos"
        selected={pathname === '/tickets'}
        onClickEvent={refreshSide}
      />

      <Icon
        to="/contacts"
        image={contacts}
        imageWhenSelected={contactsSelected}
        label="Kontakte"
        selected={pathname === '/contacts'}
        onClickEvent={refreshSide}
      />

      {UserLibrary.isBusinessAccount() && (
        <Icon
          to="/business"
          image={business}
          imageWhenSelected={businessSelected}
          label="Business"
          selected={pathname === '/business'}
          onClickEvent={refreshSide}
        />
      )}

      <Icon
        to="/logout"
        image={logout}
        imageWhenSelected={logoutSelected}
        label="Logout"
        selected={pathname === '/logout'}
        onClickEvent={refreshSide}
      />
    </div>
  );
}
