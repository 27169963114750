import React, { forwardRef } from 'react';

/**
 * Select component
 * @param {Object} [props]
 * @param {string} [props.label]
 * @param {Array} [props.options]
 * @param {'' | 'light'} [props.theme] - Theme
 * @param {string | null} [props.labelInOption] - Label inside select as placeholder option
 * @param {boolean} [props.marked] - Different style
 * @param {Fucntion} [props.onOptionChange] - Callback on select onChange event
 * @param {*} [props.children] - React component(s)
 * @component
 */
export default forwardRef(({
  label ='',
  options = [{ name: '', value: '' }],
  theme = '',
  labelInOption = null,
  marked = false,
  onOptionChange = () => {},
  children,
  ...resetProps
}, ref) => (
  <div
    className={[
      'select-holder',
      theme === 'light' ? 'light' : '',
      marked ? 'marked' : '',
    ].join(' ')}
  >
    {label && <span>{label}</span>}
    <select
      ref={ref}
      {...resetProps}
      onChange = {onOptionChange}
    >
      {typeof labelInOption === 'string' && <option value="" hidden>{labelInOption}</option>}

      {options.map((option, i) => <option key={i} value={option.value}>{option.name}</option>)}

      {children}
    </select>
  </div>
));
