import errorMessages from '../config/errorcodes';

export const getErrorMessage = arrayErrors => {
  let formattedErrors = [];
  arrayErrors.forEach(errorId => {
    if (errorId && errorMessages[errorId]) {
      formattedErrors.push(errorMessages[errorId])
    }
  });

  if (formattedErrors.length) {
    return formattedErrors
  }
  return false;
}

const requsetErrorMessage = (errors, defaultMessage = 'Hoppla! Da ist etwas schief gegangen.') => {
  let arrayErrors = errors

  if(!arrayErrors) {
    return ""
  } 

  if (!Array.isArray(errors)) {
    arrayErrors = [errors];
  }

  return arrayErrors.length ? getErrorMessage(arrayErrors) || defaultMessage : defaultMessage;
};

export default requsetErrorMessage;
