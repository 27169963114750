import React from 'react';
import notAvailable from '../../assets/images/icons/Stop.svg';

/**
 * NoMatch component for SquarePasses
 * @component
 */
export default () => (
  <div className="SquarePasses-Item">
    <div className="SquarePasses-Item-body middle">
      <img className="notavailable" src={notAvailable} alt="No match"/>
    </div>
  </div>
);
