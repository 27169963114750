import React from 'react';

/**
 * Paragraph component
 * @param {Object} [props]
 * @param {string | [string]} [props.children] - React component(s)
 * @param {'' | 'light'} [props.theme] - Theme style
 * @component
 */
export default ({ children, theme = '', ...restProps }) => typeof children === 'string'
  ? <p
      className={[
        'Paragraph',
        theme === 'light' ? 'light' : '',
      ].join(' ')}
      {...restProps}
    >

      {children}

    </p>
  : (Array.isArray(children)
    ? children.map(text => (
      <p
        key={text}
        className={[
          'Paragraph',
          theme === 'light' ? 'light' : '',
        ].join(' ')}
        {...restProps}
      >
        {text}
      </p>
    ))
    : children);
