import React from 'react';
import { useLocation } from 'react-router-dom';
import Home from './Home';
import Landing from './Landing';
import UserLibrary from '../../libraries/User';
import redirectConfig from '../../config/redirect';

/**
 * Home page
 * @component
 */
export default () => {
  const location = useLocation();

  // If query string destination is set to games, set a __REDIRECT_TO_GAMES__ flag
  if (location.search.indexOf('destination=games') !== -1) {
    window.__REDIRECT_TO_GAMES__ = true;
  }

  if (window.__REDIRECT_TO_GAMES__ && UserLibrary.isLoggedIn()) {
    window.location.replace(redirectConfig.games);
    return null;
  }

  return UserLibrary.isLoggedIn() ? <Home /> : <Landing />;
};
