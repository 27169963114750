import React, { useEffect } from 'react';

/**
 * Side component for Container component
 * @param {Object} [props]
 * @param {boolean} [props.nospace] - Remove left and right empty space
 * @param {boolean} [props.hideSide] - Is Side hidden
 * @param {boolean} [props.hideOnlyOnMob] - Side can hide only on mobile sreens
 * @param {*} [props.children] - React component(s)
 * @component
 */
const Side = ({ nospace = false, hideSide = false, hideOnlyOnMob = false, children }) => {
  useEffect(() => {
    if (window.innerWidth < 991) {
      window.scrollTo(0, 0)
    }
  }, [hideSide])

  return (
    <div 
      className={[
        'Container-Side',
        nospace ? 'nospace' : '',
        hideSide ? 'hide' : '',
        hideOnlyOnMob ? 'hideOnMob': ''
      ].join(' ')}
    >
      <div className="Container-Side-Content">
        {children}
      </div>
    </div>
  )
};

Side.container = true;

export default Side;
