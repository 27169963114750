import React from 'react';
import Item from './Item';
import TimeLibrary from '../../libraries/Time';
import editIcon from '../../assets/images/icons/Edit_contact.svg';

/**
 * Pass component
 * @param {Object} [props]
 * @param {string} [props.image] - Image url
 * @param {string} [props.club] - Club name
 * @param {string | null} [props.dateTime] - Date and time
 * @param {string} [props.stadium] - Name of stadium
 * @param {string} [props.city] - City name
 * @param {Function} [props.EditClick] - Edit click callback method
 * @param {*} [props.children] - Item component(s)
 * @component
 */
const Pass = ({ image = '', club = '', dateTime = null, stadium = '', city = '', onEditClick, children }) => (
  <div className="Pass">
    <header className="Pass-header">
      {!!image && <img src={image} alt="Club Logo" />}

      <div className="Pass-header-text">
        {!!club && <h2 className="title">{club}</h2>}

        <div className="Pass-header-text-info">
          <div className="Pass-header-text-info-datetime">
            {!!dateTime ? (
              <>
                <span>{TimeLibrary.dayAndDate(dateTime)},</span>

                <span>{TimeLibrary.time(dateTime)} Uhr</span>
              </>
            ) : <span><em>Verschoben</em></span>}
          </div>

          <div className="Pass-header-text-info-place">
            <span>{stadium}</span>

            <span>{city }</span>
          </div>
        </div>
      </div>
    </header>

    <div className="Pass-section">
      <span>Abo</span>

      <span>Platz</span>

      <span>Typ</span>

      <span>Status</span>

      {typeof onEditClick === 'function' && (
        <div className="Pass-Item-edit" onClick={onEditClick}>
          <img src={editIcon} alt="Edit"/>
        </div>
      )}
    </div>

    <div>
      {children}
    </div>
  </div>
);

Pass.Item = Item;

export default Pass;
