import React, { useState, useEffect } from 'react';
import {
  useHistory,
  Redirect,
} from 'react-router-dom';
import {
  useQuery,
  useMutation,
  queryCache,
} from 'react-query';
import Page from '../components/Page';
import Container from '../components/Container';
import Title from '../components/Title';
import HeaderTitle from '../components/HeaderTitle';
import Info from '../components/Info';
import Hr from '../components/Hr';
import Button from '../components/Button';
import UserForm from './../components/UserForm';
import Loader from './../components/Loader';
import ErrorMessages from './../components/ErrorMessages';
import ChangePassword from './../components/ChangePassword';
import Paragraph from './../components/Paragraph';
import UserLibrary from '../libraries/User';
import TimeLibrary from '../libraries/Time';
import filterObjectLibrary from '../libraries/filterObject';
import api from '../api';
import EditIcon from '../assets/images/icons/Edit.svg'
import redirectConfig from '../config/redirect';

/**
 * Profile page
 * @component
 */
export default () => {
  const history = useHistory();
  const [editProfileOption, setEditProfileOption] = useState(false);
  const [changePassOption, setChangePassOption] = useState(false);
  const [hideSide, setHideSide] = useState(false);
  const [errors, setErrors] = useState(null);
  const [IsErrors, setIsErrors] = useState(null);
  const isPasswordSet = UserLibrary.GetIsPasswrodSet();
  const { data, isLoading, error, isError } = useQuery('profile', api.user.data, {
    onSuccess({ data }) {
      UserLibrary.setDataCompleted(!!data.isCompleted);

      UserLibrary.SetIsPasswordSet(!!data.isPasswordSet);

      // UserLibrary.setBusinessAccount(!!data.roles?.includes('BUSINESS') || !!data.roles?.includes('BUSINESS_ADMIN'));

      // UserLibrary.setBusinessAdmin(!!data.roles?.includes('BUSINESS_ADMIN'));

      // Temporary enable all business accounts editing
      UserLibrary.setBusinessAccount(!!data.roles?.includes('BUSINESS'));
      UserLibrary.setBusinessAdmin(!!data.roles?.includes('BUSINESS'));

      UserLibrary.update({
        firstName: data.first_name || '',
        lastName: data.last_name || '',
      });
    },
  });
  const [update, { isLoading: updateLoading, error: updateError }] = useMutation(api.user.update, {
    onSuccess(userData) {
      setEditProfileOption(false);
      setChangePassOption(false);
      setHideSide(false);

      queryCache.setQueryData('profile', queryCacheData => ({
        ...queryCacheData,
        data: {
          ...queryCacheData.data,
          ...userData.data,
      }}));

      UserLibrary.setDataCompleted(!!userData.data.isCompleted);
      UserLibrary.SetIsPasswordSet(!!userData.data.isPasswordSet);
      UserLibrary.update({
        firstName: userData.data.first_name || '',
        lastName: userData.data.last_name || '',
      });

      // Check if __REDIRECT_TO_GAMES__ flag is set, then redirect if it is ture
      if (window.__REDIRECT_TO_GAMES__) {
        window.location.replace(redirectConfig.games);

        return null;
      }
    },
  });

   // Reset errors on Profile and Password update
  useEffect(() => {
    setIsErrors(!!updateError?.data?.message);

    setErrors(!!updateError?.data?.message ? updateError.data.message : '') ;
  }, [updateError]);

  useEffect(() => {
    setIsErrors(null);

    setErrors(null) ;
  }, [editProfileOption, changePassOption]);

  if (window.__REDIRECT_TO_GAMES__ && UserLibrary.isLoggedIn()) {
    window.location.replace(redirectConfig.games);
  } else if (!UserLibrary.isLoggedIn()) {
    // If user is not logged in, redirect to login page
    return <Redirect to="/login" />;
  }

  // Conver data from server
  const converUserData = userData => ({
    'Vorname': userData.first_name,
    'Nachname': userData.last_name,
    'Strasse & Nr': userData.street,
    'PLZ': userData.zip,
    'Ort': userData.city,
    'Land': userData.country,
    'Geburtsdatum': TimeLibrary.date(userData.birthday),
    'Handynummer': userData.phone,
    'Email-Adresse': userData.email,
  });

  // Only return data from list (array)
  const filterUserData = userData => filterObjectLibrary(userData, [
    'accept_marketing',
    'birthday',
    'city',
    'country',
    'first_name',
    'gender',
    'last_name',
    'phone',
    'street',
    'zip',
  ]);

  const handleLogoutClick = () => history.push('/logout');

  return (
    <Page>
      <HeaderTitle>MySCB</HeaderTitle>

      <Container>
        <Container.SideMenu refreshSide={() => {
            setHideSide(false)
            setEditProfileOption(false);
            setChangePassOption(false);
          }}
        />

        <Container.Side hideOnlyOnMob={hideSide}>
          {isLoading ? <Loader style={{ marginBottom: 30 }} /> : !isError && (
            <>
              <Title
                small
                subtitle="Mein Konto"
                theme="side"
              >
                {data?.data ? `${data.data.first_name} ${data.data.last_name}` : ''}
              </Title>

              {data?.data && <Info data={converUserData(data.data)} />}

              <Hr />

              <Button
                fullwidth
                disabled={editProfileOption}
                margin="bottom"
                onClick={() => {
                  setChangePassOption(false)
                  setEditProfileOption(true)
                  setHideSide(true)
                }}
                icon = {{src: EditIcon, alt: "icon"}}
              >
                Daten anpassen
              </Button>

              <Button
                fullwidth
                disabled={changePassOption}
                margin="bottom"
                onClick={() => {
                  setEditProfileOption(false)
                  setChangePassOption(true)
                  setHideSide(true)
                }}
              >
                {isPasswordSet ? "Passwort ändern" : "Passwort erstellen"}
              </Button>
            </>
          )}

          <Button
            fullwidth
            onClick={handleLogoutClick}
          >
            Logout
          </Button>
        </Container.Side>

        {!isLoading && !data?.data.isCompleted && (
          <div className="help-info">
            {/* Please fill in all the fields so you can use the platform */}
            <Paragraph>
              Bitte füllen Sie alle Felder aus, damit Sie die Plattform nutzen können
            </Paragraph>
          </div>
        )}

        {!!error?.data?.message && <ErrorMessages messages={error.data.message} />}

        {!isLoading && !isError && editProfileOption &&
          <UserForm
            hasGender
            hasMarketing
            user={filterUserData(data.data)}
            mandatory = {["gender", "first_name", "last_name", "street", "zip", "city", "country", "birthday", "phone"]}
            title="Daten anpassen"
            confirmLabel="Speichern"
            cancelLabel="Abbrechen"
            onConfirm={update}
            onCancel={() => {
              setEditProfileOption(false)
              setHideSide(false)
            }}
            isLoading={updateLoading}
            isError={IsErrors}
            errorMsg={errors}
          />}
          {!isLoading && !isError && changePassOption &&
            <ChangePassword
              title = {isPasswordSet ? "Passwort ändern" : "Passwort erstellen"}
              hasCurrentPassword={isPasswordSet}
              confirmLabel={isPasswordSet ? "Passwort ändern" : "Passwort erstellen"}
              cancelLabel="Abbrechen"
              onConfirm={update}
              onCancel={() => {
                setChangePassOption(false)
                setHideSide(false)
              }}
              isLoading={updateLoading}
              isError={IsErrors}
              errorMsg={errors}
            />
          }
      </Container>
    </Page>
  );
}
