import React from 'react';
import placeholder from '../../assets/images/icons/Profile.svg';
import edit from '../../assets/images/icons/Edit.svg';

/**
 * Item for ProfileList component
 * @param {Object} [props]
 * @param {string} [props.image] - Image url
 * @param {Object} [props.profilePerson] - Contact person
 * @param {Function} [props.onEditClick] - Edit profile callback
 * @param {boolen} [props.editDisabled] - Edit icon button disabled
 * @param {boolen} [props.selected] - Selected style
 * @component
 */
export default ({
  image = placeholder,
  profilePerson = {},
  onEditClick = () => {},
  editDisabled = false,
  selected = false,
  ...restProps
}) => (
  <div className={`Item${selected ? ' selected' : ''}`} {...restProps} onClick={!editDisabled ? onEditClick : () => {}}>
    <img src={image || placeholder} alt="Profile" className="Item-profile" />

    <div className="Item-info">
      <h3 className="Item-info-name">
        {profilePerson.first_name} {profilePerson.last_name}

        {!!profilePerson.role && (
          <span style={{ fontWeight: 'normal', marginLeft: 5 }}>
            ({profilePerson.role})
          </span>
        )}
      </h3>

      <h4 className="Item-info-phone">{profilePerson.phone_mobile}</h4>
    </div>

    <img
      src={edit}
      alt="Edit"
      className={editDisabled ? 'Item-edit disabled' : 'Item-edit'}
    />
  </div>
);
