import React, { forwardRef, useRef, useEffect } from 'react';
import { useMutation } from 'react-query';
import { FaImage } from 'react-icons/fa';
import Input from '../Input';
import Loader from '../Loader';
import api from '../../api';

/**
 * InputImage component
 * @param {Object} [props]
 * @param {boolean} [props.disabled] - Disabled component
 * @param {Function} [props.onLoading] - Callback method when loading status is changed
 * @param {Function} [props.onImageUpload] - Callback method when image is uploaded
 * @component
 */
export default forwardRef(({ disabled = false, onLoading = () => {}, onImageUpload = () => {}, ...restProps }, ref) => {
  const inputImage = useRef();
  const [upload, { isLoading }] = useMutation(api.upload.uploadImage, {
    onSuccess: ({ data }) => onImageUpload(data.imageUrl),
    onError: () => alert('Foto-Upload fehlgeschlagen!'),
  });

  useEffect(() => void(onLoading(isLoading)), [isLoading, onLoading]);

  const handleImageChange = () => upload(inputImage.current.files[0]);

  return (
    <>
      <Input
        disabled={disabled}
        style={{ paddingRight: 50 }}
        ref={ref}
        {...restProps}
        type="url"
      >
        <div className="InputImage" style={{ top: restProps?.label ? 25 : 0 }}>
          <label
            htmlFor={disabled || isLoading ? '' : 'inputimage'}
            className={disabled || isLoading ? 'disabled' : ''}
          >
            {isLoading ? <Loader /> : <FaImage />}
          </label>

          <input
            ref={inputImage}
            id="inputimage"
            type="file"
            accept="image/*"
            onChange={handleImageChange}
          />
        </div>
      </Input>
    </>
  );
});
