import React, { forwardRef, useState } from 'react';
import EyeIcon from "../../assets/images/icons/Eye_Black_on.svg";
import EyeIconOff from "../../assets/images/icons/Eye_Black_off.svg";

/**
 * Input component
 * @param {Object} [props]
 * @param {string} [props.label] - Label text
 * @param {boolean} [props.error] - Input with error
 * @param {Object} [props.icon] - Input with icon
 * @param {'' | 'light'} [props.theme] - Theme
 * @param {boolean} [props.isPassword] - Input is type password
 * @param {boolean} [props.disabled] - Input field is disabled
 * @param {boolean} [props.fullwidth] - Full size input
 * @param {boolean} [props.marked] - Different style
 * @param {*} [props.children] - React component(s)
 * @component
 */
export default forwardRef(({ label = '',
  error = false,
  icon = {},
  theme = '',
  ispassword = false,
  disabled = false,
  fullwidth = false,
  marked = false,
  children = null,
  ...resetProps
}, ref) => {
  const [textVisibility, setTextVisibility] = useState(
    ispassword ?  false : true
  );

  return (
    <div
      className={[
        'input-wrap',
        theme === 'light' ? 'light' : '',
        fullwidth ? 'fullwidth' : '',
      ].join(' ')}>

      {!!label &&
        <div className="label">
          <span>{label} </span> {!!marked && <span style={{fontSize: 10, opacity: 0.35, letterSpacing: 0.3}}> (Privat)</span>}
        </div>
      }

      <input
        ref={ref}
        className={[
          error ? 'error' : '',
          disabled || resetProps?.readOnly ? 'disabled' : '',
        ].join(' ')}
        disabled={disabled}
        type = {textVisibility ? "text" : "password"}
        {...resetProps}
      />

      <span className="error-msg">{error && <p>Daten fehlen</p>}</span>

      {icon && Object.keys(icon).length !==0 && <div className="icon"><img src={icon.src} alt="icon" onClick={icon.onClick}/></div>}

      {ispassword &&
        <div className="icon">
          {textVisibility ? <img src={EyeIconOff} alt="icon" onClick={() => setTextVisibility(!textVisibility)}/> :
            <img src={EyeIcon} alt="icon" onClick={() => setTextVisibility(!textVisibility)}/>
          }
        </div>
      }

      {children}
    </div>
  )
});
