import React from 'react';
import {FaTimes} from 'react-icons/fa';

/**
 * Header for Modal component
 * @param {Object} [props]
 * @param {string} [props.title] - Title text
 * @param {Function} [props.onCloseClick] - Callback method when X is clicked
 * @component
 */
export default ({ title = '', onCloseClick = () => {} }) => {
  return (
    <div className="Modal-Header">
      <h2 className="Modal-Header-title">{title}</h2>

      <span
        className="Modal-Header-closebutton"
        onClick={onCloseClick}
      >
        <FaTimes/>
      </span>
    </div>
  );
};
