import API from '../libraries/API';

/**
 * Get contact by ID
 * @param {Object} params
 * @param {string} params.contact_id - Username
 */
const contact = ({ contact_id }) => API.get(`contact/${contact_id}`);

/**
 * Update/edit contact by ID
 * @param {Object} params
 * @param {string} params.contact_id - ID of contact
 * @param {string} params.first_name - First name
 * @param {string} params.last_name - Last name
 * @param {string} params.street - Street address
 * @param {string} params.zip - ZIP code
 * @param {string} params.city - City name
 * @param {string} params.country - Country name
 * @param {string} params.phone - Phone number
 * @param {string} params.birthday - Date of birth
 */
const updateContact = ({ contact_id, ...restParams }) => API.patch(`contact/${contact_id}`, restParams);

/**
 * Get all contacts
 */
const contacts = () => API.get('contact');

/**
 * Add/create new contact
 * @param {Object} params
 * @param {string} params.first_name - First name
 * @param {string} params.last_name - Last name
 * @param {string} params.street - Street address
 * @param {string} params.zip - ZIP code
 * @param {string} params.city - City name
 * @param {string} params.country - Country name
 * @param {string} params.phone - Phone number
 * @param {string} params.birthday - Date of birth
 */
const createContact = params => API.post(`contact`, params);

/**
 * Delete contact by ID
 * @param {Object} params
 * @param {string} params.contact_id - ID of contact
 */
const deleteContact = ({ contact_id }) => API.delete(`contact/${contact_id}`);

/**
 * Add/create new contact if there is no one
 * @param {Object} params
 * @param {string} params.first_name - First name
 * @param {string} params.last_name - Last name
 * @param {string} params.street - Street address
 * @param {string} params.zip - ZIP code
 * @param {string} params.city - City name
 * @param {string} params.country - Country name
 * @param {string} params.phone - Phone number
 * @param {string} params.birthday - Date of birth
 */
const createNonExistentContact = params => {
  const isEqual = (val1, val2) => typeof val1 === 'string' && typeof val2 === 'string' && val1.trim() === val2.trim();

  const cancels = [];

  const promise = new Promise((resolve, reject) => {
    const getContact = API.get('contact')
      .then(({ data }) => {
        if (data.find(contact => (isEqual(params.first_name, contact.first_name) && isEqual(params.last_name, contact.last_name) && isEqual(params.phone, contact.phone)))) {
          return resolve();
        }

        const postContact = API.post(`contact`, params)
          .then(resolve)
          .catch(reject);

          cancels.push(postContact.cancel);
      })
      .catch(reject);

    cancels.push(getContact.cancel);
  });

  promise.cancel = () => cancels.forEach(cancel => {
    try {
      cancel();
    } catch (error) {
      console.warn(error);
    }
  });

  return promise;
};

export default {
  contact,
  updateContact,
  contacts,
  createContact,
  deleteContact,
  createNonExistentContact,
}
