import React from 'react';

/**
 * Field component for TicketCodeInput
 * @param {Object} [props]
 * @param {boolean} [props.active] - Focued field
 * @param {string} [props.children] - A letter/character
 * @param {Function} [props.onClick] - Callback method when filed is clicked
 * @component
 */
export default ({ active = false, children = '', onClick = () => {} }) => (
  <div
    className={active ? 'TicketCodeInput-Field active' : 'TicketCodeInput-Field'}
    onClick={onClick}
  >
    {children}
  </div>
);
