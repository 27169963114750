import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  useMutation,
  queryCache,
} from 'react-query';
import Page from './../components/Page';
import Container from './../components/Container';
import HeaderTitle from './../components/HeaderTitle';
import Title from './../components/Title';
import ErrorMessages from '../components/ErrorMessages';
import Loader from './../components/Loader';
import UserLibrary from '../libraries/User';
import api from '../api';

/**
 * Logout page
 * @component
 */
export default () => {
  const history = useHistory();
  const [logout, { isLoading, error }] = useMutation(api.auth.logout);

  useEffect(() => {
    logout()
      .then(() => {
        UserLibrary.remove();

        queryCache.clear();

        history.push('/');
      })
      .catch(console.dir);
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page>
      <HeaderTitle>MySCB</HeaderTitle>

      <Container>
        <Container.SideMenu />

        <Title>
          Logout
        </Title>

        {!!error?.data?.message && <ErrorMessages messages={error.data.message} />}

        {isLoading && (
          <Loader
            size="big"
            style={{ justifyContent: 'flex-start' }}
          />
        )}
      </Container>
    </Page>
  );
};
