/**
 * Convert object with empty string value(s) to null value(s)
 * @param {Object} data - Data
 * @returns {Object}
 */
export default (data = {}) =>
  Object.keys(data)
    .reduce((accumulator, key) => ({
      ...accumulator,
      [key]: data[key] === '' ? null : data[key],
    }), {});
