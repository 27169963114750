import API from '../libraries/API';

/**
 * Get Voucher's data
 * @param {string} voucher_code - Voucher code
 */
const voucher = (_, voucher_code) => API.get(`voucher/internal/${voucher_code}`);

/**
 * Redeem Voucher
 * @param {Object} params
 * @param {string} params.voucher_code - Voucher code
 */
const redeemVoucher = ({ voucher_code }) => API.post(`voucher/internal`, { voucher_code });

export default {
  voucher,
  redeemVoucher,
}
