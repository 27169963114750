import React from 'react';
import Modal from '../../components/Modal';
import Button from '../../components/Button';
import ErrorMessages from '../../components/ErrorMessages';
import requsetErrorMessage from '../../libraries/requsetErrorMessage';

/**
 * Alert modal
 * @param {Object} [props]
 * @param {boolean} [props.isOpen] - Show/hide modal
 * @param {string} [props.title] - Title text
 * @param {string} [props.message] - Message/content text
 * @param {string | [string]} [props.errorMessages] - Error(s) from server
 * @param {'small' | 'default' | 'big'} [props.size] - Modal width size
 * @param {string} [props.positiveLabel] - Text in positive button
 * @param {string} [props.negativeLabel] - Text in negative button
 * @param {Function} [props.onPositiveClick] - Callback method when positive button is clicked
 * @param {Function} [props.onNegativeClick] - Callback method when negative button is clicked
 * @param {Function} [props.onRequestClose] - Callback method when modal should be closed
 * @param {boolen} [props.isLoading] - Spinner activ on button after request is sent
 * @component
 */
export default ({
  isOpen = false,
  title = '',
  message = '',
  errorMessages = '',
  size = 'default',
  positiveLabel = 'OK',
  negativeLabel = '',
  onPositiveClick = () => {},
  onNegativeClick = () => {},
  onRequestClose = () => {},
  isLoading = false
}) => (
  <Modal
    isOpen={isOpen}
    title={title}
    size={size}
    onRequestClose={onRequestClose}
  >
    {/* Message */}
    {!!message && <p className="Alert-message">{message}</p>}

    {!!errorMessages && <ErrorMessages messages={requsetErrorMessage(errorMessages)} />}

    {/* Button(s) container */}
    {(!!positiveLabel || !!negativeLabel) && (
      <div className="Alert-buttoncontainer">
        {/* Positive button */}
        {!!positiveLabel && (
          <Button
            loading={isLoading}
            disabled={isLoading}
            onClick={onPositiveClick}
          >
            {positiveLabel}
          </Button>
        )}

        {/* Negative button */}
        {!!negativeLabel && (
          <Button
            theme="dark"
            onClick={onNegativeClick}
          >
            {negativeLabel}
          </Button>
        )}
      </div>
    )}
  </Modal>
);
