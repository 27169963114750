import queryString from 'query-string';
import UserLibrary from './User';

/**
 * APi library for requests
 */
export default class API {
  static get(address, params) {
    return API.request('GET', address, params);
  }

  static post(address, params) {
    return API.request('POST', address, params);
  }

  static patch(address, params) {
    return API.request('PATCH', address, params);
  }

  static put(address, params) {
    return API.request('PUT', address, params);
  }

  static delete(address, params) {
    return API.request('DELETE', address, params);
  }

  static request(method = 'POST', address = '', params) {
    // Create a new AbortController instance for this request
    const controller = new AbortController();

    const contentType = API.contentType(params);

    const config = {
      method,
      headers: {
        Accept: 'application/json',
        'Content-Type': contentType,
      },
      signal: controller.signal, // Pass the abortController's signal to your request
    };

    // GET request query string
    let query = '';

    // Check if it is GET request
    if (method === 'GET' && params) {
      query = `?${queryString.stringify(params)}`;
    } else if(contentType === 'multipart/form-data') {
      const formData  = new FormData();

      for(const key in params) formData.append(key, params[key]);

      // This is fix for fetch to send reqest with Content-Type as multipart/form-data
      delete config.headers['Content-Type'];

      config.body = formData;
    } else {
      config.body = JSON.stringify(params);
    }

    const promise = new Promise((resolve, reject) => {
      fetch(`${window.location.origin}/api/${address}${query}`, config)
        .then(async response => {
          let data = null;

          try {
            data = await response.json();
          } catch(e) {}

          const object = {
            data,
            status: response.status,
            statusText: response.statusText,
          };

          if (response.status === 401) {
            UserLibrary.remove();
          }

          response.ok ? resolve(object) : reject(object);
        })
        .catch(reject);
    });

    promise.cancel = () => controller.abort();

    return promise;
  }

  static contentType(params) {
    if (typeof params !== 'object' || Array.isArray(params) || params === null) {
      return 'application/json';
    }

    return Object.keys(params).find(key => params[key] instanceof FileList || params[key] instanceof File) ? 'multipart/form-data' : 'application/json';
  }
}
