import React, { useState, useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import footerConfig from '../../config/footer';


/**
 * Check if Footer should be visible or not
 * @param {string} pathname - URL pathname
 */
const isIgonredPage = pathname => !!footerConfig.ignorePages.find(page => !!pathname.includes(page));

/**
 * Footer component
 * @param {Object} [props]
 *
 * @component
 */
export default () => {
  const location = useLocation();
  const [hide, setHide] = useState(isIgonredPage(location.pathname));

  // Check pages where Footer should be ignored
  useEffect(() => { setHide(isIgonredPage(location.pathname)) }, [location]);


  return (
    hide ? null :
    <footer className="SCB-footer">
      <div className="container">
        <div className="meta">
          <div className="left">
            <span>© SC Bern Eishockey AG 1931 - {new Date().getFullYear()}</span>
            <span> | </span>
            <span>Mingerstrasse 12B, 3014 Bern</span>
          </div>
          <div className="right">
            <a href="https://www.scb.ch/nutzungsbedingungen" target="_blank" rel="noopener noreferrer">Nutzungsbedingungen</a>
            <a href="https://www.scb.ch/datenschutz" target="_blank" rel="noopener noreferrer">Datenschutz</a>
            <a href="https://www.scb.ch/impressum" target="_blank" rel="noopener noreferrer">Impressum</a>
            <a href="https://www.scb.ch/medien" target="_blank" rel="noopener noreferrer">Medien</a>
          </div>
        </div>
      </div>
    </footer>
  )
};
