export default {
  // User Errors
  "UserErrors.UserNotFound": "Benutzer wurde nicht gefunden / Passwort falsch.",
  "UserErrors.UserEmailAlreadyExists":
    "Für diese Email-Adresse besteht bereits ein MySCB-Account. Bitte loggen Sie sich ein!",
  "UserErrors.UserWithoutEmailAddress": "Email-Adresse fehlt.",
  "UserErrors.UserEmailNotVerified":
    "Email-Adresse wurde noch nicht bestätigt.",
  "UserErrors.ExistingPasswordIncorrect": "Falsches Passwort.",
  "UserErrors.VerificationLinkExpired":
    "Bestätigungslink ist nicht mehr gültig.",
  "UserErrors.TokenExpired": "Token nicht mehr gültig.",
  "UserErrors.UpdateFailed": "Das Update konnte nicht abgeschlossen werden.",
  "UserErrors.EmailWasNotSent": "Ein Email konnte nicht gesendet werden",
  "UserErrors.UserEmailAlreadyVerified":
    "Sieht so aus, als hätten Sie Ihr Konto bereits verifiziert!",
  "Password must be at least 8 characters and include one lowercase letter, one uppercase letter, and one digit.":
    "Das Passwort muss aus mindestens 8 Zeichen bestehen und einen Kleinbuchstaben, einen Großbuchstaben und eine Ziffer enthalten.",
  "zip must be shorter than or equal to 4 characters":
    "PLZ muss kürzer oder gleich 4 Zeichen sein",
  "phone must be a valid phone number":
    "Telefon muss eine gültige Telefonnummer sein",
  "email must be an email": "Bitte verwenden Sie eine gültige E-Mail-Adresse",
  "birthday must be a ISOString": "Falsches Datumsformat",
  "birthday should not be empty": "Fehlender Geburtstag.",
  // Pass Errors
  "PassErrors.PassNotFound": "Dies ist kein Ticketcode!",
  "PassErrors.PassGameNotFound": "Dieses Ticket ist für dieses Spiel ungültig!",
  "PassErrors.PassAlreadyAssigned": "Dieses Ticket wurde schon benutzt!",
  // Access Errors
  "AccessErrors.AccessUsed": "Dieses Ticket wurde schon benutzt!",
  "AccessErrors.AccessAlreadyScanned": "Dieses Ticket wurde schon gescannt.",
  "AccessErrors.GameHasEnded": "Dieses Spiel ist schon zu Ende.",
  "Game Has Ended": "Dieses Spiel ist schon zu Ende. ", //???
  "AccessErrors.NotFound": "Kein gültiges Ticket!",
  "AccessErrors.NoAccessToken": " Es fehlt der Access Token!",
  "AccessErrors.IncompleteAccess": "Es fehlen Daten für dieses Ticket.",
  "AccessErrors.NoAccessCode": "Dein Ticketcode ist noch nicht verfügbar.",
  // Contact Errors
  "ContactErrors.NotFound": "Kontakt nicht gefunden",
  // Postgres Errors
  23505: "Hoppla! Da ist etwas schief  gegangen.",
  "23502 ": "Hoppla! Da ist etwas schief  gegangen.",
  "VoucherErrors.NotFound": "Gutschein nicht gefunden!",
  "AcquiredItemErrors.AlreadyUsed": "Gutschein bereits verwendet!",
  "VoucherErrors.AlreadyUsed": "Gutschein bereits verwendet!",
  "VoucherErrors.IncorrectType":
    "Dieser Gutschein ist leider nur gültig ab der Version 1.43 der SCB-App. Bitte update deine SCB-App jetzt, damit du den Gutschein einlösen kannst.",
  "BusinessProfileErrors.EmailAlreadyAssigned":
    "Diese MySCB-Account E-Mail wurde bereits verwendet.",
  "BusinessProfileErrors.UserNotFound":
    "Diese MySCB-Account E-Mail existiert nicht.",
};
