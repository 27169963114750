import API from '../libraries/API';

/**
 * Fetch all passes
 */
const passes = () => API.get('user/me/passes?createAccesses=true');

/**
 * Assign pass
 * @param {Object} params
 * @param {string} params.assign_token - Token code
 */
const assign = ({ assign_token }) => API.patch(`pass/assign/${assign_token}`);

export default {
  passes,
  assign,
}
