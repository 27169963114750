import React from 'react';
import TimeLibrary from '../../libraries/Time';

/**
 * Button component
 * @param {Object} props
 * @param {Object} props.data - Key of object is label (title) and value if data
 * @component
 */
export default ({ data }) => {
  return typeof data !== 'object' ? null : (
    <div className="Info">
      {Object.keys(data).map(key => (
        <div key={key} className="Info-row">
          <label>
            {key}
          </label>

          <p>
            {key === "GEBURTSDATUM" ? TimeLibrary.date(data[key]) : data[key]}
          </p>
        </div>
      ))}
    </div>
  )
};
