import React from 'react';
import logoIcon from '../../assets/images/scb-logo.png';

/**
 * Pass component for SquarePasses
 * @param {Object} [props]
 * @param {number} [props.passIndex] - Pass number
 * @param {string} [props.assignToken] - Assign token
 * @component
 */
export default ({ passIndex = 0, assignToken = '', passType = '', passSeat }) => (
  <div className="SquarePasses-Item">
    <div className="SquarePasses-Item-body">
      <img className="logo" src={logoIcon} alt="Logo" />

      <h3>Abo {passIndex ? passIndex.toString() : ''}</h3>

      <span className="token">{assignToken}</span>

      {/* {passType && <span className="type">{passType}</span>} */}

      <br/>
      {passSeat && <span className="type">{`${passSeat.seat_group.title} ${passSeat.row ? `/ ${passSeat.row}` : ''}  ${passSeat.num ? `/ ${passSeat.num}` : ''}`}</span>}

    </div>
  </div>
);
