import React from 'react';
import {
  FaCheck,
  FaTimes,
} from 'react-icons/fa';
import { MdReply } from 'react-icons/md'
import ticketIcon from '../../assets/images/sidemenu-icons/Ticket_white.svg';
import { Link } from 'react-router-dom';

/**
 * User component for SquarePasses
 * @param {Object} [props]
 * @param {string} [props.name] - Name of person
 * @param {string} [props.phone] - Phone number
 * @param {string} [props.token] - Asigned token
 * @param {string} [props.link] - Anonymous link (link for sharing)
 * @param {boolean} [props.scanned] - Ticket is scanned or not
 * @param {boolean} [props.inactive] - Inactive pass (game is over)
 * @param {boolean} [props.editHide] - Hide or show edit button link
 * @param {Function} [props.onEditClick] - Callback method when edit is clicked
 * @component
 */
export default ({ name = '', phone = '', token='', link = '', scanned = false, inactive = false, editHide = false, onEditClick = () => {} }) => (
  <div className="SquarePasses-Item user">
    <div className="SquarePasses-Item-body">
      <h3 className="name">{name}</h3>

      <span className="phone">{phone}</span>

      <span className="link">{link}</span>

    </div>

    {scanned ? (
      <div className="SquarePasses-Item-icon">
        <FaCheck size={24} style={{ opacity: inactive ? 0.25 : 1 }} />
      </div>
    ) : (inactive ? (
      <div className="SquarePasses-Item-icon">
        <FaTimes size={24} style={{ opacity: 0.25 }} />
      </div>
      ) : null)}
    {token && <Link to={`/eticket/${token}?closable=true`} className={`SquarePasses-Item-ETicket ${(scanned || inactive) ? "bottom" : ""}`} target="_blank">
      <img src={ticketIcon} alt="ETicket" style={{ width: 20, marginRight: 5 }} /> eTicket
    </Link>}
    {!scanned && !inactive && !editHide && (
      <div className="SquarePasses-Item-footer" onClick={onEditClick}>
        <span><MdReply size={24} style={{ transform: 'scaleX(-1)', marginBottom: -6 }} /> Ticket zuteilen</span>
      </div>
    )}
  </div>
);
