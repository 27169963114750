import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import googleAnalyticsConfig from '../../config/googleAnalytics';

/**
 * Google Analytics component
 * @component
 */
export default () => {
  const location = useLocation();

  useEffect(() => {
    // This line of code will NOT use Google Analytics in development
    !window.location.href.includes('localhost') && !window.ga && ReactGA.initialize(googleAnalyticsConfig.trackingID);

    // This line of code will use Google Analytics in development (with debug flag)
    // !window.ga && ReactGA.initialize(googleAnalyticsConfig.trackingID, { debug: !window.location.href.includes('localhost') });
  }, []);

  useEffect(() => { window.ga && ReactGA.pageview(location.pathname + location.search) }, [location]);

  return null;
};
