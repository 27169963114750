import React from "react";
import MapImg from "./../../assets/images/eticket/SCB_Corona-Lageplan_nru_2009010_L3-1.png";
import {FaTimes} from 'react-icons/fa';

const MapView = (props) => {
  return (
    <div className="map-view">
      <div className="map-wrapper">
        <img src={MapImg} alt="Map"/>
        <span className="close" onClick={props.close}>
          <FaTimes />
        </span>
      </div>
    </div>
  )
}

export default MapView;