import React, { useEffect } from 'react';
import {
  useHistory,
  Redirect,
} from 'react-router-dom';
import UserLibrary from '../../libraries/User';

/**
 * Page component
 * @param {Object} [props]
 * @param {boolean} [props.redirect] - Check user current status and redirect to other page if necessary
 * @param {*} [props.children] - React component(s)
 * @component
 */
export default ({ redirect = false, children }) => {
  const history = useHistory();
  const pathname = history?.location?.pathname || '';

  useEffect(() => { window.scrollTo(0, 0); }, []);

  // Redirect to proper page if necessary
  if (redirect) {
    if (pathname !== '/login' && !UserLibrary.isLoggedIn()) {
      return <Redirect to="/login" />;
    } else if (pathname !== '/verifyEmail' && !UserLibrary.isEmailVerified()) {
      return <Redirect to="/verifyEmail" />;
    } else if (pathname !== '/profile' && !UserLibrary.isDataCompleted()) {
      return <Redirect to="/profile" />;
    }
  }

  return(<div className="Page">{children}</div>)
};
