import React, {
  useState,
  useRef,
  useEffect,
} from 'react';
import {
  useQuery,
  useMutation,
  queryCache,
} from 'react-query';
import Page from '../components/Page';
import HeaderTitle from '../components/HeaderTitle';
import Container from '../components/Container';
import Loader from './../components/Loader';
import Title from '../components/Title';
import Paragraph from '../components/Paragraph';
import Hr from '../components/Hr';
import Button from '../components/Button';
import TicketCodeInput from '../components/TicketCodeInput';
import Switcher from '../components/Switcher';
import Pass from '../components/Pass';
import SquarePasses from '../components/SquarePasses';
import ErrorMessages from './../components/ErrorMessages';
import Help from './../components/Help';
import GuestModal from '../modals/Guest';
import Alert from '../modals/Alert';
import requsetErrorMessage from './../libraries/requsetErrorMessage';
import api from '../api';
import squaresIcon from '../assets/images/icons/Square_List.svg';
import listIcon from '../assets/images/icons/List_Black.svg';
import PlusIcon from '../assets/images/icons/Plus.svg';

/**
 * Minimum width to SquarePasses been shown
 */
const minSquarePassesWidth = 1300;

/**
 * Guest modal public fields
 * @type {[string]}
 */
const publicFields = ['first_name', 'last_name', 'phone'];

/**
 * Tickets page
 * @component
 */
export default () => {
  const [view, setView] = useState(window.innerWidth < minSquarePassesWidth ? 'list' : 'squares');
  const [visibleSwitcher, setVisibleSwitcher] = useState(window.innerWidth >= minSquarePassesWidth);
  const [ticketCode, setTicketCode] = useState('');
  const [guestData, setGuestData] = useState(null);
  const [hideSide, setHideSide] = useState(true);
  const [errors, setErrors] = useState(null);
  const [IsErrors, setIsErrors] = useState(null);
  const [lotteryRequest, setLotteryRequest] = useState(null);
  const ticketCodeInput = useRef();
  const { data, error, isLoading, isError } = useQuery('passes', api.pass.passes);
  const { data: contacts } = useQuery('contacts', api.contact.contacts);
  const [assign, { error: assignError, isLoading: isAssignLoading }] = useMutation(api.pass.assign, {
    onSuccess(data) {
      queryCache.setQueryData('passes', data);

      ticketCodeInput.current.clear();

      setHideSide(true);
    },
  });
  const [access, { error: accessError, isLoading: isAccessLoading }] = useMutation(api.access.access, {
    onSuccess({ data: access }, { game_id, pass_id }) {
      queryCache.setQueryData('passes', queryCacheData => ({
        ...queryCacheData,
        data: queryCacheData.data.map(qd => {
          if (qd.id !== game_id) {
            return qd;
          } else {
            return qd.passes.find(pass => pass.id === pass_id)
              ? { ...qd, passes: qd.passes.map(pass => pass.id === pass_id ? { ...pass, access } : pass) }
              : qd
          }
        }),
      }));
    },
  });
  const [accesses, { error: accessesError, isLoading: isAccessesLoading }] = useMutation(api.access.accesses, {
    onSuccess(data) {
      queryCache.setQueryData('passes', data);
    },
  });
  const [accessRequest, { error: accessRequestError, isLoading: isAccessRequestLoading, reset: accessRequestReset }] = useMutation(api.access.accessRequest, {
    onSuccess() {
      queryCache.setQueryData('passes', queryCacheData => ({
        ...queryCacheData,
        data: queryCacheData.data.map(qd => {
          if (qd.id !== lotteryRequest.gameId) {
            return qd;
          } else {
            return qd.passes.find(pass => pass.id === lotteryRequest.passId)
              ? { ...qd, passes: qd.passes.map(pass => pass.id === lotteryRequest.passId ? { ...pass, request_status: 'applied' } : pass) }
              : qd
          }
        }),
      }));

      setLotteryRequest(null);
    },
  });
  const [createNonExistentContact, { isLoading: isCreateNonExistentContactLoading }] = useMutation(api.contact.createNonExistentContact, {
    onSuccess(data) {
      if (!data) return;

      queryCache.setQueryData('contacts', queryCacheData => !queryCacheData ? queryCacheData : ({
        ...queryCacheData,
        data: [
          ...queryCacheData.data,
          data.data,
      ]}));
    },
  });

  useEffect(() => {
    if (data && data.data && passesIds().length > 5) {
      setView('list');

      setVisibleSwitcher(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    const resize = () => {
      if (window.innerWidth < minSquarePassesWidth) {
        setView('list');

        setVisibleSwitcher(false);
      } else {
        setVisibleSwitcher(true);
      }
    };

    resize();

    window.addEventListener('resize', resize);

    return () => window.removeEventListener('resize', resize);
  }, []);

  // Reset errors on Assigning ticket
  useEffect(() => {
    setIsErrors(!!accessError?.data?.message || !!accessesError?.data?.message);

    setErrors(!!accessError?.data?.message ? accessError.data.message : (!!accessesError?.data?.message ? accessesError.data.message : ''));
  }, [accessError, accessesError]);

  useEffect(() => {
    setIsErrors(null);

    setErrors(null);
  }, [guestData]);

  /**
   * Invite link with token
   * @param {string} token - Anonymous token
   * @returns {string}
   */
  const inviteLink = token => `${window.location.origin}/invite/${token}`;

  /**
   * Find all ID(s) from pass(es)
   * @returns {[number]}
   */
  const passesIds = () => {
    let ids = [];

    data.data.forEach(game => game.passes.forEach(pass => ids.push(pass.id)));

    // Unique ID(s)
    return [...new Set(ids)];
  }

  /**
   * Find all passes with pass details
   * @returns {[object]}
   */
  const getAllPasses = () => {
    let passes = [];

    data.data.forEach(game => game.passes.forEach(pass => {if (!passes.find(p => p.id === pass.id)) {
      passes.push({id: pass.id, token: pass.assign_token, type: pass.type, seat: pass.seat})
    }}));

    return passes;
  }

  /**
   * Find access
   * @param {number} gameId - ID of game
   * @returns {string}
   */
  const accessByGame = gameId => {
    const { passes } = data.data.find(game => game.id === gameId) || {};

    return passesIds().map(passId => Array.isArray(passes) ? passes.find(pass => pass.id === passId)?.access : undefined);
  };

  /**
   * Has anonymous link
   * @param {number} gameId - ID of game
   * @param {number} passId - ID of pass
   * @returns {boolean}
   */
  const hasAnonymousLinkByIds = (gameId, passId) => {
    const { passes } = data.data.find(game => game.id === gameId) || {};

    const pass = passes.find(pass => pass.id === passId) || {};

    return !!pass.create_anonymous_links;
  };

  /**
   * Findout is game is finished
   * @param {number} gameId - ID of game
   * @returns {boolean}
   */
  const isGameOver = gameId => (!!data.data.find(game => game.id === gameId)?.hasEnded || false);

  /**
   * Findout is game has a date and time
   * @param {number} gameId - ID of game
   * @returns {boolean}
   */
  const hasGameDate = gameId => !!data.data.find(game => game.id === gameId)?.time;

  /**
   * Find request status for given pass ID
   * @param {number} passId - ID of pass
   * @param {number} gameId - ID of game
   * @returns {string}
   */
  const requestStatus = (passId, gameId) => data.data.find(game => game.id === gameId)?.passes.find(pass => pass.id === passId)?.request_status || null;

  /**
   * Find draw date and time
   * @param {number} gameId - ID of game
   * @returns {string | null}
   */
  const drawDate = gameId => data.data.find(game => game.id === gameId)?.draw_date || null;

  const accessComponent = gameId => (access, index) => {
    const passId = passesIds()[index];
    const hasAnonymousLink = hasAnonymousLinkByIds(gameId, passId);

    if (!!access) {
      return access.anonymous_token ? (
        <SquarePasses.Invite
          key={index}
          onInviteClick={() => setGuestData({
            game_id: gameId,
            pass_id: passId,
            has_anonymous_link: hasAnonymousLink,
            anonymous_link: access.anonymous_token ? inviteLink(access.anonymous_token) : '',
          })}
        />
      ) : (
        <SquarePasses.User
          key={index}
          name={`${access.first_name || ''} ${access.last_name || ''}`}
          phone={access.phone}
          token={access.access_token}
          scanned={!!access.scanned_at}
          inactive={hasGameDate(gameId) ? isGameOver(gameId) : false}
          editHide={!hasGameDate(gameId)}
          onEditClick={() => {
            setGuestData({
              game_id: gameId,
              pass_id: passId,
              has_anonymous_link: hasAnonymousLink,
              anonymous_link: access.anonymous_token ? inviteLink(access.anonymous_token) : '',
            })}}
        />
      );
    } else if (access === null) {
      const status = requestStatus(passId, gameId);

      return status ? (
        <SquarePasses.Lottery
          key={index}
          status={status}
          dateTime={drawDate(gameId) || ''}
          disabled={isGameOver(gameId) || !hasGameDate(gameId)}
          onClick={() => {
            if (status === 'needs_grant') {
              setLotteryRequest({ gameId, passId });
            } else if (status === 'granted') {
              setGuestData({
                game_id: gameId,
                pass_id: passId,
                has_anonymous_link: hasAnonymousLink,
                anonymous_link: '',
              });
            }
          }}
        />
      ) : (
        <SquarePasses.Edit
          key={index}
          disabled={isGameOver(gameId) || !hasGameDate(gameId)}
          onClick={() => {
              setGuestData({
              game_id: gameId,
              pass_id: passId,
              has_anonymous_link: hasAnonymousLink,
              anonymous_link: '',
            })}}
        />
      );
    } else if (access === undefined) {
      return <SquarePasses.NoMatch key={index} />;
    }
  };

  const findGuestData = params => {
    if (typeof params !== 'object' || !params.game_id || !params.pass_id) {
      return {};
    }

    return data.data
      .find(game => game.id === params.game_id)
      .passes
      .find(pass => pass.id === params.pass_id)
      .access;
  };

  const handleGuestModalConfirm = userData => {
    const withGameId = (userData.assign_pass || !guestData.game_id) ? false : true

    userData.assign_pass && delete userData.assign_pass

    const guestUserData = findGuestData(guestData);

    const guestId = guestData ? guestUserData?.id : null;

    const promise = withGameId
      ? access({
        // user's data
        ...(() => {
          if (!guestUserData?.last_viewed_at) {
            return userData;
          }

          if (guestUserData?.phone !== userData?.phone) {
            return userData;
          }

          return Object.keys(userData).reduce((accumulator, key) => ({
            ...accumulator,
            ...publicFields.indexOf(key) >= 0
              ? { [key]: userData[key] }
              : (userData[key] !== null && userData[key] !== guestUserData[key] ? { [key]: userData[key] } : {}),
          }), {});
        })(),
        ...guestId ? { id: guestId } : {},
        game_id: guestData.game_id,
        pass_id: guestData.pass_id,
      })
      : accesses({ ...userData, pass_id: guestData.pass_id });

    Promise.allSettled([promise, createNonExistentContact(userData)])
      .catch(console.warn)
      .finally(() => setGuestData(null));
  };

  // TODO: handle update of all games from one response
  const handleCreateLink = ({ data: { anonymous_token } }) => {
    const { game_id, pass_id } = guestData;

    queryCache.setQueryData('passes', queryCacheData => ({
      ...queryCacheData,
      data: queryCacheData.data.map(qd => {
        if (qd.id !== game_id) {
          return qd;
        } else {
          return qd.passes.find(pass => pass.id === pass_id)
            ? { ...qd, passes: qd.passes.map(pass => pass.id === pass_id ? { ...pass, access: { ...pass.access, anonymous_token } } : pass) }
            : qd
        }
      }),
    }));

    setGuestData(data => ({
      ...data,
      has_anonymous_link: true,
      anonymous_link: inviteLink(anonymous_token),
    }));
  };

  const sordData = () => {
    let d = data?.data;

    if (!d) return d;

    d.forEach(el => {
      if (el.passes) {
        for (let i = 0; i < el.passes.length; i++) {
          const pass = el.passes[i];

          if (pass.seat) {
            pass.__sort__ = `${pass.seat.seat_group.sector.title} ${pass.seat.seat_group.title} ${pass.seat.row} ${pass.seat.num}`;
          }
        }
      }
    });

    d = d.map(row => ({
      ...row,
      passes: row.passes.sort((a, b) => a.__sort__ === [a.__sort__, b.__sort__].sort()[0] ? -1 : 1),
    }));

    return d;
  };

  const handleLotteryRequestClose = () => {
    accessRequestReset();

    setLotteryRequest(null);
  };

  return (
    <Page redirect>
      <HeaderTitle>MySCB</HeaderTitle>

      <Container>
        <Container.SideMenu refreshSide={() => setHideSide(true)}/>

        <Container.Side hideSide={hideSide}>
          <Title
            small
            theme="side"
          >
            Saisonabo(s)
          </Title>

          <Paragraph>
            Hier kannst du den Code deines Saisonabo eingeben, den du von uns per Brief zugestellt erhalten hast, um dein Saisonabo zu aktivieren.
          </Paragraph>
          <Paragraph>
            Gib den Code oder die Codes (falls du mehrere Saisonabos verwaltest) in den folgenden Feldern ein.
          </Paragraph>


          <Hr />

          <h3 style={{
            margin: '20px 0',
            padding: 0,
            color: 'white',
            fontSize: 16,
          }}>
            Code Saisonabo(s)
          </h3>

          <TicketCodeInput
            ref={ticketCodeInput}
            onChange={setTicketCode}
          />

          {!!assignError?.data?.message && <ErrorMessages messages={requsetErrorMessage(assignError.data.message)} />}

          <Button
            fullwidth
            margin="top"
            disabled={ticketCode.length !== 11}
            loading={isAssignLoading}
            onClick={() => assign({ assign_token: ticketCode })}
          >
            Saisonabo aktivieren
          </Button>

          <Hr />
        </Container.Side>

        <Title>
          Match und Saisonabo auswählen
        </Title>

        {!!error?.data?.message && <ErrorMessages messages={requsetErrorMessage(error.data.message)} />}

        {isLoading ? <Loader style={{ marginBottom: 30 }} /> : !isError && (
          <>
            <div className = "action-buttons">
              <Button
                onClick={() => setHideSide(!hideSide)}
                icon={{ src: PlusIcon, alt: 'icon' }}
                style={{ maxWidth: 260, textAlign: 'left', lineHeight: '26px' }}
              >
                Neues Saisonabo hinzufügen
              </Button>
              {visibleSwitcher && !!data && (
                <Switcher
                  align="right"
                >
                  <Switcher.Button
                    active={view === 'squares'}
                    icon={squaresIcon}
                    onClick={() => setView('squares')}
                  />

                  <Switcher.Button
                    active={view === 'list'}
                    icon={listIcon}
                    onClick={() => setView('list')}
                  />
                </Switcher>
              )}
            </div>

            {view === 'squares' && data && data.data && !!data.data.length && (
              <SquarePasses>
                <SquarePasses.Row>
                  <SquarePasses.Games />

                  {data && data.data && getAllPasses().map(pass => (
                    <SquarePasses.Pass
                      key={pass.id}
                      assignToken={pass.token}
                      passType={pass.type}
                      passSeat={pass.seat}
                    />
                  ))}
                </SquarePasses.Row>

                {data && data.data && data.data.map(game => (
                  <SquarePasses.Row key={game.id}>
                    <SquarePasses.Club
                      clubLogo={game.opponent_logo}
                      clubName={game.opponent}
                      dateTime={game.time}
                    />

                    {accessByGame(game.id).map(accessComponent(game.id))}
                  </SquarePasses.Row>
                ))}
              </SquarePasses>
            )}

            {view === 'list' && data && data.data && sordData().map(game => hasGameDate(game.id) && isGameOver(game.id) ? null : (
              <Pass
                key={game.id}
                image={game.opponent_logo}
                club={game.opponent}
                dateTime={game.time}
                stadium={game.location}
                city="Bern"
              >
                {game.passes.map(pass => (
                  <Pass.Item
                    key={pass.id}
                    assignToken={pass.assign_token}
                    accessToken={pass.access?.access_token}
                    status={false}
                    drawDate={drawDate(game.id) || ''}
                    place={pass.seat ? `${pass.seat.seat_group.title} ${pass.seat.row ? `/ ${pass.seat.row}` : ''}  ${pass.seat.num ? `/ ${pass.seat.num}` : ''}` : ''}
                    name={pass.access ? `${pass.access.first_name || ''} ${pass.access.last_name || ''}` : ''}
                    // type={''}
                    scanned={pass.alreadyScanned}
                    inactive={hasGameDate(game.id) ? isGameOver(game.id) : false}
                    phone={pass.access?.phone || ''}
                    invite={!!pass.access?.anonymous_token}
                    editDisabled={!hasGameDate(game.id)}
                    onEditClick={pass.request_status === 'needs_grant' ? () => setLotteryRequest({ gameId: game.id, passId: pass.id })
                      : () => setGuestData({
                        game_id: game.id,
                        pass_id: pass.id,
                        has_anonymous_link: hasAnonymousLinkByIds(game.id, pass.id),
                        anonymous_link: pass.access?.anonymous_token ? inviteLink(pass.access.anonymous_token) : '',
                      })
                    }
                  />
                ))}
              </Pass>
            ))}
          </>
        )}

        {!!guestData && (
          <GuestModal
            privateFields={guestData ? !!findGuestData(guestData)?.last_viewed_at : false}
            user={guestData ? findGuestData(guestData) : {}}
            anonymousLinkData={guestData.has_anonymous_link ? {
              gameId: guestData.game_id,
              passId: guestData.pass_id,
              link: guestData.anonymous_link,
              disabled: isGameOver(guestData.game_id),
            } : {}}
            contacts={contacts && contacts.data ? contacts.data : null}
            isOpen={!!guestData}
            isLoading={isAccessLoading || isAccessesLoading || isCreateNonExistentContactLoading}
            title="Angaben des Matchbesuchers"
            onConfirm={handleGuestModalConfirm}
            onRequestClose={isAccessLoading || isAccessesLoading || isCreateNonExistentContactLoading ? () => {} : () => setGuestData(null)}
            onCreateLink={handleCreateLink}
            isError={IsErrors}
            errorMsg={errors}
            hasPassAssigningCheckbox={guestData.game_id}
          />
        )}

        <Alert
          isOpen={!!lotteryRequest}
          title="Teilnahme"
          message="An Lotterie für Ticket teilnehmen?"
          errorMessages={accessRequestError}
          positiveLabel="Ja"
          negativeLabel="Nein"
          onPositiveClick={() => accessRequest({ game_id: lotteryRequest.gameId, pass_id: lotteryRequest.passId })}
          onNegativeClick={handleLotteryRequestClose}
          onRequestClose={handleLotteryRequestClose}
          isLoading={isAccessRequestLoading}
        />
      </Container>
    </Page>
  );
}
