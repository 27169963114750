import React from "react";
import SCBLogo from "./../../assets/images/eticket/Logo_SC_Bern.svg"
import MySCBLogo from "./../../assets/images/eticket/Logo_MYSCB.svg"

const Header = () => {
  return (
    <header>
      <div className="external-page-container">
        <div className="header">
          <img src={SCBLogo} alt="scb logo"/>
          <img src={MySCBLogo} alt="myscb logo"/>
        </div>
      </div>
    </header>
  )
};

export default Header;