import React from 'react';
import { ReactQueryConfigProvider } from 'react-query';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from 'history';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import Header from './components/header/header';
import Home from './pages/Home';
import Login from './pages/Login';
import Logout from './pages/Logout';
import Registration from './pages/Registration';
import Profile from './pages/Profile';
import Tickets from './pages/Tickets';
import Contacts from './pages/Contacts';
import Business from './pages/Business';
import ETicket from './pages/eTicket';
import NoPage from './pages/NoPage';
import ResetPassword from './pages/ResetPassword';
import VerifyEmail from './pages/VerifyEmail';
import DeleteAccount from './pages/DeleteAccount';
import reactQueryConfig from './config/reactQuery';
import Invite from './pages/Invite';
import GoogleAnalytics from './components/GoogleAnalytics';
import Footer from './components/Footer';
import RedeemVoucher from './pages/RedeemVoucher';

const SentryRoute = Sentry.withSentryRouting(Route);
const history = createBrowserHistory()
Sentry.init({
  dsn: 'https://37358000b3b94400bf9ededc21086b17@o542436.ingest.sentry.io/5962677',
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
  tracesSampleRate: 0.05,
});

export default () => (
  <ReactQueryConfigProvider config={reactQueryConfig}>
    <Router>
      <GoogleAnalytics />
      <Header />

      <Switch>
        <SentryRoute exact path="/" component={Home}/>
        <SentryRoute exact path="/login" component={Login}/>
        <SentryRoute exact path="/logout" component={Logout}/>
        <SentryRoute exact path="/registration" component={Registration}/>
        <SentryRoute path="/resetPassword" component={ResetPassword}/>
        <SentryRoute exact path='/eticket/:token' component={ETicket}/>
        <SentryRoute exact path='/voucher/:voucher_code' component={RedeemVoucher}/>
        <SentryRoute exact path='/invite/:anonymous_token' component={Invite}/>
        <SentryRoute exact path="/profile" component={Profile}/>
        <SentryRoute exact path="/tickets" component={Tickets}/>
        <SentryRoute exact path="/contacts" component={Contacts}/>
        <SentryRoute exact path="/business" component={Business}/>
        <SentryRoute exact path="/verifyEmail" component={VerifyEmail}/>
        <SentryRoute exact path="/deleteAccount" component={DeleteAccount}/>
        <SentryRoute exact path="*" component={NoPage}/>
      </Switch>

      <Footer/>
    </Router>
  </ReactQueryConfigProvider>
);
