import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  useQuery,
  useMutation,
  queryCache,
} from 'react-query';
import Header from '../components/ExternalPage/Header';
import TicketView from '../components/eTicket/eTicketView';
import UserForm from '../components/UserForm';
import Loader from '../components/Loader';
import ErrorMessages from '../components/ErrorMessages';
import filterObjectLibrary from '../libraries/filterObject';
import requsetErrorMessage from '../libraries/requsetErrorMessage';
import api from '../api'
import ErrorIcon from '../assets/images/icons/error.png'

/**
 * ETicket page
 * @component
 */
export default ({ match }) => {
  const isTicketClosable = (new URLSearchParams(useLocation().search)).get('closable')?.toLowerCase() === 'true';
  const [showForm, setShowForm] = useState(false);
  const { data, error, isLoading } = useQuery(['eticket', match.params.token, { own_ticket: isTicketClosable }], api.access.eTicket);
  const [update, { isLoading: isUpdateLoading, error: updateError, isError: isUpdateError }] = useMutation(api.access.updateETicket, {
    onSuccess({ data }) {
      queryCache.setQueryData(['eticket', match.params.token], queryCacheData => ({
        ...queryCacheData,
        data: { ...data },
      }));

      setShowForm(false);
    },
  });

  const dataForServer = guestData => filterObjectLibrary(guestData, [
    'first_name',
    'last_name',
    'accept_marketing',
  ]);

  const onSubmitHandler = guestData => update({ ...dataForServer(guestData), access_token: match.params.token });

  return (
    <div className="external-page">
      <Header/>

      {!!error?.data?.message &&
        <center>
          <img src={ErrorIcon} alt="error" style={{ marginTop: 50, width: 60 }}/>

          <ErrorMessages messages={requsetErrorMessage(error.data.message)} />
        </center>
      }

      {!showForm ? (
        isLoading ? <Loader style={{ marginTop: 100 }} /> : (
          !data?.data ? null : (
            <TicketView
              guest={data.data}
              guestForm={() => setShowForm(true)}
              ticketChecked={!!data.data}
              isTicketClosable={isTicketClosable}
            />
          )
        )
      ) : (
        <div className="external-page-container">
          <UserForm
            hasMarketing
            hasDataProtectionConfirmation
            hasZip={false}
            hasAddress={false}
            hasCity={false}
            hasCountry={false}
            hasBirthday={false}
            phoneDisabled
            user={data.data}
            mandatory={["data_protection"]}
            title="ANGABEN DES MATCHBESUCHERS"
            confirmLabel="SPEICHERN"
            cancelLabel="ABBRECHEN"
            onConfirm={onSubmitHandler}
            onCancel={() => setShowForm(false)}
            isLoading={isUpdateLoading}
            isError={isUpdateError}
            errorMsg={isUpdateError && updateError.data.message}
          />
        </div>
      )}
    </div>
  );
};
