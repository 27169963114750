import React from 'react';
import Page from '../components/Page';
import Container from '../components/Container';
import HeaderTitle from '../components/HeaderTitle';
import Title from '../components/Title';

export default () => {
  return (
    <Page>
      <HeaderTitle />

      <Container>
        <div className="welcome-screen">
          <Title>
            Mein MySCB-Konto löschen
          </Title>

          <p className="Paragraph">
            Es ist natürlich schade, dass du uns verlassen willst. Um dein MySCB-Konto zu löschen, musst du eine Email von dem zu löschenden Konto and folgende Email-Adresse schicken: <a href="mailto:myscb@scb.ch" style={{ color: '#d8232a' }}>myscb@scb.ch </a><br></br>Innerhalb von einem Arbeitstag werden wir deinen Account löschen.
          </p>
        </div>
      </Container>
    </Page>
  )
};
