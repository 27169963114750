/**
 * Remove key(s) from object
 * @param {Object} data - Data
 * @param {[string]} includeList - List of key(s) to be removed from data
 * @returns {Object}
 */
export default (data = {}, includeList = []) =>
  includeList
    .reduce((accumulator, value) => ({
      ...accumulator,
      ...data.hasOwnProperty(value) ? { [value]: data[value] } : {},
    }), {});
