import Cookies from 'universal-cookie';

const cookies = new Cookies();

// Name of storage (key)
const STORAGE_KEY = 'user_';

// Cookie expires time in ms
const expiresTime = 7 * 24 * 60 * 60 * 1000;

/**
 * Convert local storage to cookie, if there is one
 * TODO: After sometime this IIFE can be removed
 */
(() => {
  if (!localStorage) return;

  const user = localStorage.getItem(STORAGE_KEY);

  if (!user) return;

  cookies.set(STORAGE_KEY, JSON.parse(user), { path: '/', expires: new Date(Date.now() + expiresTime) });

  localStorage.removeItem(STORAGE_KEY);
})();

/**
 * User library
 */
export default class User {
  /**
   * Save user's data
   * @param {Object} data - User's data
   */
  static set(data) {
    cookies.set(STORAGE_KEY, data, { path: '/', expires: new Date(Date.now() + expiresTime) });
  }

  /**
   * Read user's data
   * @param {string} key - Key of value to return or if not set, return object
   * @returns {Object | string | undefined}
   */
  static get(key = '') {
    let user = cookies.get(STORAGE_KEY, { path: '/' });

    // No user's data
    if (!user) {
      return undefined;
    }

    // If key arg is not set return object
    if (!key) {
      return user;
    }

    // If key exit in object, return value of key
    if (user[key]) {
      return user[key];
    }

    return undefined;
  }

  /**
   * Update user's data
   * @param {Object} data - User's data to update
   */
  static update(data = {}) {
    let user = User.get();

    // Check user's data
    if (!user) {
      return;
    }

    User.set({
      ...user,
      ...data,
    });
  }

  /**
   * Remove user's data
   */
  static remove() {
    cookies.remove(STORAGE_KEY, { path: '/' });
  }

  /**
   * Save email verification as true
   * @param {boolean} verifiedEmail - Is email address verified
   */
  static setEmailVerified(verifiedEmail = true) {
    User[!!User.get() ? 'update' : 'set']({ verifiedEmail });
  }

  /**
   * Check does user is logged in
   */
  static isLoggedIn() {
    return !!User.get();
  }

  /**
   * Check does user verified email address
   * @returns {boolean}
   */
  static isEmailVerified() {
    return !!User.get('verifiedEmail');
  }

  /**
   * Save user's data completed status
   * @param {boolean} dataCompleted - Does user's data are fulfilled
   */
  static setDataCompleted(dataCompleted = true) {
    User[!!User.get() ? 'update' : 'set']({ dataCompleted });
  }

  /**
   * Check does user fulfilled data
   * @returns {boolean}
   */
  static isDataCompleted() {
    return !!User.get('dataCompleted');
  }

  /**
   * Save user's account as business or not
   * @param {boolean} dataCompleted - Does user's data are fulfilled
   */
  static setBusinessAccount(businessAccount = false) {
    User[!!User.get() ? 'update' : 'set']({ businessAccount });
  }

  /**
   * Check does user's account is business
   * @returns {boolean}
   */
  static isBusinessAccount() {
    return !!User.get('businessAccount');
  }

  /**
   * Save user's account as business or not
   * @param {boolean} dataCompleted - Does user's data are fulfilled
   */
  static setBusinessAdmin(businessAdmin = false) {
    User[!!User.get() ? 'update' : 'set']({ businessAdmin });
  }

  /**
   * Check does user's account is business
   * @returns {boolean}
   */
  static isBusinessAdmin() {
    return !!User.get('businessAdmin');
  }

  /**
   * Save is user's password set
   * @param {boolean} isPasswordSet - is password set
   */
  static SetIsPasswordSet(isPasswordSet = true) {
    User[!!User.get() ? 'update' : 'set']({ isPasswordSet });
  }

  /**
   * Check if password is set
   * @returns {boolean}
   */
  static GetIsPasswrodSet() {
    return !!User.get('isPasswordSet');
  }
}
