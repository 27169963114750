import React from 'react';
import { FaRegClock } from 'react-icons/fa';
import TimeLibary from '../../libraries/Time';
import editIcon from '../../assets/images/icons/Edit_contact.svg';

/**
 * Lottery component for SquarePasses
 * @param {Object} [props]
 * @param {'needs_grant' | 'applied' |  'denied' | 'granted'} [props.status] - Show different content depending on the status value
 * @param {string} [props.dateTime] - Date and time of lottery end
 * @param {boolean} [props.disabled] - Disable component
 * @param {Function} [props.onClick] - Callback method when item is clicked
 * @component
 */
export default ({ status = '', dateTime = '', disabled = false, onClick = () => {} }) => !status ? null : (
  <div
    className={[
      'SquarePasses-Item',
      status === 'granted' ? 'edit lottery' : 'lottery',
      status === 'applied' ? 'wait' : '',
      status === 'needs_grant' ? 'pointer' : '',
      disabled ? 'disabled' : '',
    ].join(' ')}
    onClick={!disabled ? onClick : () => {}}
  >
    {/* <div className={`SquarePasses-Item-body ${status === 'granted' ? 'middle' : ''}`} style={{ padding: 10 }}>
      {status === 'needs_grant' && (
        <>
          <h4 className="editlabel" style={{ textAlign: 'left' }}>An Lotterie für Matchbesuch teilnehmen</h4>
        </>
      )}

      {status === 'applied' && (
        <>
          <h4 className="editlabel" style={{ textAlign: 'left' }}>An Lotterie für Matchbesuch teilgenommen</h4>

          <FaRegClock size={24} style={{ marginTop: 5 }} />
        </>
      )}

      {status === 'denied' && <h4 className="editlabel" style={{ textAlign: 'left' }}>Leider Pech gehabt! Hoffentlich klappt es beim nächsten Spiel</h4>}

      {status === 'granted' && (
        <>
          <img className="editcontacticon" src={editIcon} alt="Edit"/>

          <h4 className="editlabel">Matchbesucher<br />definieren</h4>
        </>
      )}
    </div>

    <div className="SquarePasses-Item-footer nohover">
      {!!dateTime && ['needs_grant', 'applied'].includes(status) && <span className="date" style={{ textAlign: 'center' }}>Ziehung am<br />{TimeLibary.date(dateTime)}, {TimeLibary.time(dateTime)}</span>}

      {status === 'granted' && <h5 className="footerlabel">Ticket gewonnen</h5>}
    </div> */}
  </div>
);
