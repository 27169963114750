import React, { useState } from 'react';
import Header from '../components/ExternalPage/Header';
import Loader from './../components/Loader';
import Input from './../components/Input';
import Button from './../components/Button';
import { useForm } from 'react-hook-form';
import ErrorMessages from './../components/ErrorMessages';
import requsetErrorMessage from '../libraries/requsetErrorMessage';
import { 
  useQuery,
  useMutation,
 } from 'react-query';
import api from '../api'

/**
 * Invite page
 * @component
 */
export default ({ match }) => {
  const [phone, setPhone] = useState('');
  const [updateCompleted, setUpdateCompleted] = useState(false);
  const { register, handleSubmit, errors } = useForm({ criteriaMode: 'all' });

  const { data, error, isLoading } = useQuery(['invite', match.params.anonymous_token], api.access.invite);
  const [updateInvite, { isLoading: isUpdateLoading, error: updateError }] = useMutation(api.access.updateInvite, {
    onSuccess() {
      setUpdateCompleted(true)
    },
  });
 
  const handlePhoneChange = (e) => {
    setPhone(e.target.value)
  };

  return (
    <div className="external-page">
      <Header/>

      <center style={{ maxWidth: 991, margin: "auto", marginTop: 100}}>
        {!!error?.data?.message && 
          <p style={{ color: "white", fontSize: 20, fontFamily: "Industry-Book", letterSpacing: .8 }}>Dieser Einladungslink wurde bereits verwendet</p>
        }
      </center>
      <div className="external-page-container">
        {!updateCompleted ? (isLoading ? <Loader style={{ marginTop: 100 }} /> : (
         data && data.status === 200 ? 
          <div className="form-container">
            <form onSubmit={handleSubmit(() => updateInvite({anonymous_token: match.params.anonymous_token, phone: {phone: phone}}))} style={{margin: "auto"}}>
              <Input 
                label = 'Handynummer *'
                placeholder="+41 79 1234567"
                name="phone"
                error={errors.phone}
                ref={register({ required: true })}
                onChange={handlePhoneChange}
                fullwidth
              />
              {!!updateError?.data?.message && <ErrorMessages messages={requsetErrorMessage(updateError.data.message)} />}
              <Button 
                type="submit"
                loading={isUpdateLoading}
                disabled={isUpdateLoading}
                fullwidth
              > 
                Nummer speichern
              </Button>
            </form>
          </div> : null
          )) : 
          <p style={{ color: "white", fontSize: 20, fontFamily: "Industry-Book", letterSpacing: .8, textAlign: "center" }}>{`Wir haben Ihnen eine SMS an ${phone} gesendet.`}</p>
        }
      </div>
    </div>
  );
};