import React, { forwardRef } from 'react';
import Loader from '../Loader';

/**
 * Style for Loader component
 * @type {Object}
 */
const loaderStyle = {
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
};

/**
 * Button component
 * @param {Object} [props]
 * @param {*} [props.children] - React component(s)
 * @param {boolen} [props.loading] - Loading indicator
 * @param {boolen} [props.fullwidth] - Full size button
 * @param {'' | 'dark' | 'light' | 'gray'} [props.theme] - Theme
 * @param {'' | 'top' | 'bottom' | 'vertical'} [props.margin] - Top and/or bottom margin
 * @param {Object} [props.icon] - Button with icon
 * @component
 */
export default forwardRef(({ children = null, loading = false, fullwidth = false, theme = '', margin = '', icon = { src: '', alt: '' }, ...resetProps }, ref) => (
  <button
    ref={ref}
    className={[
      'Button',
      fullwidth ? 'fullwidth' : '',
      theme === 'dark' ? 'dark' : '',
      theme === 'light' ? 'light' : '',
      theme === 'gray' ? 'gray' : '',
      margin === 'top' ? 'margin-top' : '',
      margin === 'bottom' ? 'margin-bottom' : '',
      margin === 'vertical' ? 'margin-vertical' : '',
      loading ? 'loader' : '',
    ].join(' ')}
    {...resetProps}
  >
    {icon.src && <img className = "icon" src={icon.src} alt={icon.alt} />}

    <span>
      {children}
    </span>

    {loading && (
      <Loader
        style={loaderStyle}
        theme={theme === 'light' ? 'dark' : ''}
      />
    )}
  </button>
));
