import React, { useState } from 'react';
import {
  useQuery,
  useMutation,
  queryCache,
} from 'react-query';
import Page from '../components/Page';
import Container from '../components/Container';
import HeaderTitle from '../components/HeaderTitle';
import Title from '../components/Title';
import Button from '../components/Button';
import Loader from '../components/Loader';
import Info from '../components/Info';
import Hr from '../components/Hr';
import BusinessForm from '../components/BusinessForm';
import ProfileList from '../components/ProfileList';
import ProfileForm from '../components/ProfileForm';
import ErrorMessages from '../components/ErrorMessages';
import Alert from '../modals/Alert';
import api from '../api';
import requsetErrorMessage from '../libraries/requsetErrorMessage';
import UserLibrary from '../libraries/User';
import EditIcon from '../assets/images/icons/Edit.svg'
import PlusIcon from '../assets/images/icons/Plus.svg'

export default () => {
  const [alertVisible, setAlertVisible] = useState(false);
  const [businessEdit, setBusinessEdit] = useState(false);
  const [newProfileOption, setNewProfileOption] = useState(false);
  const [profileToUpdate, setProfileToUpdate] = useState(null);
  const { data, error, isLoading, isError } = useQuery('business', api.business.data);
  const [updateBusiness, { isLoading: isBusinessLoading, error: businessError, reset: businessReset }] = useMutation(api.business.update, {
    onSuccess({ data }) {
      queryCache.setQueryData('business', queryCacheData => ({
        ...queryCacheData,
        data,
      }));

      setBusinessEdit(false);
    }
  });
  const [createProfile, { isLoading: isCreateLoading, error: createError, reset: createReset }] = useMutation(api.businessprofile.createProfile, {
    onSuccess({ data }) {
      queryCache.setQueryData('business', queryCacheData => ({
        ...queryCacheData,
        data: {
          ...queryCacheData.data,
          profiles: [
            ...queryCacheData.data.profiles,
            data,
          ],
        },
      }));

      setNewProfileOption(false);
    }
  });
  const [updateProfile, { isLoading: isUpdateLoading, error: updateError, reset: updateReset }] = useMutation(api.businessprofile.updateProfile, {
    onSuccess({ data }) {
      queryCache.setQueryData('business', queryCacheData => ({
        ...queryCacheData,
        data: {
          ...queryCacheData.data,
          profiles: queryCacheData.data.profiles.map(profile => profile.id === data.id ? data : profile),
        },
      }));

      setProfileToUpdate(null);
    }
  });
  const [deleteProfile, { isLoading: isDeleteLoading, error: deleteError, reset: deleteReset }] = useMutation(api.businessprofile.deleteProfile, {
    onSuccess({ data }) {
      
      queryCache.setQueryData('business', queryCacheData => ({
        ...queryCacheData,
        data: {
          ...queryCacheData.data,
          profiles: queryCacheData.data.profiles.filter(profile => profile.id !== data),
        },
      }));

      setProfileToUpdate(null);
      setAlertVisible(false);
    },
    onError: () => setAlertVisible(false),
  });

  // Conver data from server
  const converUserData = userData => ({
    'Name': userData.name,
    'Strasse & Nummer': userData.address || '-',
    'Ort': userData.location || '-',
    'PLZ': userData.zip || '-',
    'Postfach': userData.postbox || '-',
  });

  const handleEditBusiness = () => {
    businessReset();
    setNewProfileOption(false);
    setProfileToUpdate(null);
    setBusinessEdit(true);
  };

  const handleBusinessCancelClick = () => setBusinessEdit(false);

  const handleNewProfileOptionClick = () => {
    createReset();
    setBusinessEdit(false);
    setProfileToUpdate(null);
    setNewProfileOption(true);
  };

  const handleNewProfileCancelClick = () => setNewProfileOption(false);

  const handleProfileEditClick = profile => () => {
    updateReset();
    deleteReset();
    setBusinessEdit(false);
    setNewProfileOption(false);
    setProfileToUpdate(profile);
  };

  const handleAbortClick = () => setProfileToUpdate(null);

  const handleProfileDeleteClick = event => {
    event.preventDefault();

    setAlertVisible(true);
  };

  const isUser = () =>
  {
    return profileToUpdate?.user_id === data?.data?.user_id
  }

  return (
    <Page redirect>
      <HeaderTitle>MySCB</HeaderTitle>

      <Container>
        <Container.SideMenu />

        <Container.Side hideOnlyOnMob={businessEdit || newProfileOption || !!profileToUpdate}>
          {!isLoading && !!data?.data && (
            <>
              <Title
                small
                theme="side"
              >
                {data?.data?.name || ''}
              </Title>

              {!!data?.data?.logo && (
                <img
                  style={{ maxWidth: '100%', maxHeight: 64, marginBottom: 10 }}
                  src={data.data.logo}
                  alt="Company Logo"
                />
              )}

              <Info data={converUserData(data.data)} />

              {UserLibrary.isBusinessAdmin() && (
                <>
                  <Hr />

                  <Button
                    fullwidth
                    disabled={businessEdit || isLoading}
                    margin="bottom"
                    onClick={handleEditBusiness}
                    icon={{ src: EditIcon, alt: 'icon' }}
                  >
                    Daten anpassen
                  </Button>

                  <br /><br />
                </>
              )}

              <Title
                small
                theme="side"
              >
                Geschäftsprofile
              </Title>

              {/* Remove false check to show Add Profile button */}
              {false && (
                <Button
                  fullwidth
                  margin="bottom"
                  disabled={newProfileOption}
                  onClick={handleNewProfileOptionClick}
                  icon={{ src: PlusIcon, alt: 'icon' }}
                >
                  Profil hinzufügen
                </Button>
              )}
            </>
          )}

          {isLoading ? <Loader style={{ marginBottom: 30 }} /> : !isError && !!data?.data && (
            <ProfileList>
              {data?.data?.profiles.map(profile => (
                <ProfileList.Item
                  key={profile.id}
                  image={profile.avatar}
                  editDisabled={profileToUpdate ? profileToUpdate.id !== profile.id : false}
                  selected={profileToUpdate ? profileToUpdate.id === profile.id : false}
                  profilePerson={profile}
                  onEditClick={handleProfileEditClick(profile)}
                />
              ))}
            </ProfileList>
          )}

          {!isLoading && !data?.data && <ErrorMessages messages="Keine Daten verfügbar" />}
        </Container.Side>

        {!!error?.data?.message && <ErrorMessages messages={requsetErrorMessage(error.data.message)} />}

        {businessEdit && (
          <>
            <BusinessForm
              user={data?.data}
              title="Daten anpassen"
              isLoading={isBusinessLoading}
              confirmLabel="Speichern"
              onConfirm={updateBusiness}
              isError={!!businessError?.data?.message}
              errorMsg={businessError?.data?.message}
            />

            <div className="add-row">
              <Button
                theme="gray"
                disabled={isBusinessLoading}
                onClick={handleBusinessCancelClick}
              >
                Abbrechen
              </Button>
            </div>
          </>
        )}

        {newProfileOption &&
          <ProfileForm
            title="Profil hinzufügen"
            isLoading={isCreateLoading}
            confirmLabel="Profil hinzufügen"
            cancelLabel="Abbrechen"
            onConfirm={createProfile}
            onCancel={handleNewProfileCancelClick}
            isError={!!createError?.data?.message}
            errorMsg={createError?.data?.message}
          />
        }

        {!!profileToUpdate &&
          <>
            {/* without cancelLabel prop, delete button will not be visible */}
            <ProfileForm
              readonly={!UserLibrary.isBusinessAdmin()}
              user={profileToUpdate}
              title="Profil anpassen"
              isLoading={isUpdateLoading}
              confirmLabel={UserLibrary.isBusinessAdmin() ? 'Änderungen speichern' : undefined}
              cancelLabel={isUser() ? undefined : "Profil entfernen"}
              onConfirm={updateProfile}
              onCancel={handleProfileDeleteClick}
              isError={!!updateError?.data?.message || !!deleteError?.data?.message}
              errorMsg={updateError?.data?.message || deleteError?.data?.message}
            />

            <div className="add-row">
              <Button
                theme="gray"
                disabled={isUpdateLoading}
                onClick={handleAbortClick}
              >
                Abbrechen
              </Button>
            </div>
          </>
        }

        <Alert
          isOpen={alertVisible}
          title="Profil entfernen"
          message="Möchten Sie dieses Profil wirklich entfernen?"
          positiveLabel="Ja"
          negativeLabel="Nein"
          onPositiveClick={() => deleteProfile({ profile_id: profileToUpdate.id })}
          onNegativeClick={() => setAlertVisible(false)}
          onRequestClose={() => setAlertVisible(false)}
          isLoading={isDeleteLoading}
        />
      </Container>
    </Page>
  );
};
