import React from 'react';
import { Link } from 'react-router-dom';
import {
  FaCheck,
  FaTimes,
  FaRegClock,
} from 'react-icons/fa';
import { MdReply } from 'react-icons/md'
import { GiPerspectiveDiceSixFacesRandom } from 'react-icons/gi';
import ticketIcon from '../../assets/images/sidemenu-icons/Ticket_white.svg';
import TimeLibary from '../../libraries/Time';

/**
 * Item for Pass component
 * @param {Object} [props]
 * @param {boolean} [props.inactive] - Inactive pass
 * @param {string} [props.assignToken] - Assign token
 * @param {string} [props.accessToken] - Access token
 * @param {'needs_grant' | 'applied' |  'denied' | 'granted'} [props.status] - Show different content depending on the status value
 * @param {string} [props.drawDate] - Date and time of lottery end
 * @param {string} [props.place] - Place data
 * @param {string} [props.name] - Person name
 * @param {string} [props.phone] - Phone number
 * @param {string} [props.type] - Type of ticket
 * @param {boolean} [props.invite] - Is it invite or not
 * @param {boolean} [props.scanned] - Ticket is scanned or not
 * @param {boolean} [props.scanned] - Ticket is scanned or not
 * @param {boolean} [props.inactive] - Inactive pass (game is over)
 * @param {boolean} [props.editDisabled] - Disable edit button
 * @param {Function} [props.EditClick] - Edit click callback method
 * @component
 */
export default ({ assignToken = '', accessToken = '', status = '', drawDate = '', place = '', name = '', type = '', phone = '', invite = false, scanned = false, inactive = false, editDisabled = false, onEditClick = () => {} }) => (
  <div className="Pass-Item">
    <div className="Pass-Item-details">
      <span className="Pass-Item-filed">{assignToken}</span>

      <span className="Pass-Item-filed">{!status || status === 'granted' ? place : ''}</span>

      <span className="Pass-Item-filed">{type}</span>
      <span className="Pass-Item-name">{name}</span>

{/*
      {!!status ? (
        <span className="Pass-Item-name">
          {status === 'needs_grant' && (
            <>
              Lotterie

              {!!drawDate && <span>Ziehung am {TimeLibary.date(drawDate)}, {TimeLibary.time(drawDate)}</span>}
            </>
          )}

          {status === 'applied' && (
            <>
              An Lotterie für Matchbesuch teilgenommen

              {!!drawDate && <span>Ziehung am {TimeLibary.date(drawDate)}, {TimeLibary.time(drawDate)}</span>}
            </>
          )}

          {status === 'denied' && 'Leider Pech gehabt! Hoffentlich klappt es beim nächsten Spiel'}

          {status === 'granted' && (
            <>
              {invite ? <i>Einladen</i> : (
                <>
                  {name}

                  <span>{phone}</span>
                </>
              )}
            </>
          )}
        </span>
      ) : (
        <span className="Pass-Item-name">
          {invite ? <i>Einladen</i> : (
            <>
              {name}

              <span>{phone}</span>
            </>
          )}
        </span>
      )} */}
    </div>

    {/* {!!status ? ['needs_grant', 'applied', 'granted'].includes(status) && (
      <div className={`Pass-Item-lottery ${status === 'needs_grant' ? '' : 'inactive'}`}>
        {status === 'needs_grant' && (
          <div className={`Pass-Item-edit lottary ${editDisabled ? 'disabled' : ''}`} onClick={!editDisabled ? onEditClick : (() => {})}>
            <GiPerspectiveDiceSixFacesRandom size={24} />
          </div>
        )}

        {status === 'applied' && <FaRegClock size={24} />}

        {status === 'granted' && (
          <div className={`Pass-Item-edit lottary ${editDisabled ? 'disabled' : ''}`} onClick={!editDisabled ? onEditClick : (() => {})}>
            <img src={editIcon} alt="Edit"/>
          </div>
        )}
      </div>
    ) : null} */}
    {!status && accessToken && <Link className='Pass-Item-ETicket' to={`/eticket/${accessToken}?closable=true`} target="_blank">
      <img src={ticketIcon} alt="ETicket"/>
    </Link>}
    {!status && scanned ? (
      <div className="Pass-Item-scanned">
        <FaCheck size={24} style={{ opacity: inactive ? 0.5 : 1 }} />
      </div>
    ) : (!status && inactive ? (
        <div className="Pass-Item-scanned">
          <FaTimes size={24} style={{ opacity: 0.5 }} />
        </div>
      ) : ( !status &&
        <div className={`Pass-Item-edit ${editDisabled ? 'disabled' : ''}`} onClick={!editDisabled ? onEditClick : (() => {})}>
          <MdReply size={26} style={{ transform: 'scaleX(-1)' }} />
        </div>
    ))}
  </div>
);
