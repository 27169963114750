const countryList = [
  "Afghanistan",
  "Ägypten",
  "Albanien",
  "Algerien",
  "Amerikanisch-Samoa",
  "Amerikanische Jungferninseln",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarktis",
  "Antigua und Barbuda",
  "Äquatorialguinea",
  "Argentinien",
  "Armenien",
  "Aruba",
  "Aserbaidschan",
  "Äthiopien",
  "Australien",
  "Bahamas",
  "Bahrain",
  "Bangladesch",
  "Barbados",
  "Belgien",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivien",
  "Bosnien und Herzegowina",
  "Botswana",
  "Brasilien",
  "Britische Jungferninseln",
  "Brunei",
  "Bulgarien",
  "Burkina Faso",
  "Burundi",
  "Caymaninseln",
  "Chile",
  "China",
  "Cookinseln",
  "Costa Rica",
  "Dänemark",
  "Demokratische Republik Kongo",
  "Deutschland",
  "Dominica",
  "Dominikanische Republik",
  "Dschibuti",
  "Ecuador",
  "El Salvador",
  "Elfenbeinküste",
  "Eritrea",
  "Estland",
  "Falklandinseln",
  "Färöer",
  "Fidschi",
  "Finnland",
  "Föderierte Staaten von Mikronesien",
  "Frankreich",
  "Französisch-Guayana",
  "Französisch-Polynesien",
  "Französische Süd- und Antarktisgebiete",
  "Gabun",
  "Gambia",
  "Georgien",
  "Ghana",
  "Gibraltar",
  "Grenada",
  "Griechenland",
  "Grönland",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hong Kong",
  "Indien",
  "Indonesien",
  "Irak",
  "Iran",
  "Irland",
  "Island",
  "Israel",
  "Italien",
  "Jamaica",
  "Japan",
  "Jemen",
  "Jordanien",
  "Kambodscha",
  "Kamerun",
  "Kanada",
  "Kap Verde",
  "Kasachstan",
  "Katar",
  "Kenia",
  "Kirgistan",
  "Kiribati",
  "Kokosinseln",
  "Kolumbien",
  "Komoren",
  "Kosovo",
  "Kroatien",
  "Kuba",
  "Kuwait",
  "Laos",
  "Lesotho",
  "Lettland",
  "Libanon",
  "Liberia",
  "Libyen",
  "Liechtenstein",
  "Litauen",
  "Luxemburg",
  "Macau",
  "Madagaskar",
  "Malawi",
  "Malaysia",
  "Malediven",
  "Mali",
  "Malta",
  "Marokko",
  "Marshallinseln",
  "Martinique",
  "Mauritanien",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Monaco",
  "Mongolei",
  "Montenegro",
  "Montserrat",
  "Mosambik",
  "Myanmar, Burma",
  "Namibia",
  "Nauru",
  "Nepal",
  "Neukaledonien",
  "Neuseeland",
  "Nicaragua",
  "Niederlande",
  "Niger",
  "Nigeria",
  "Nordkorea",
  "Nördliche Marianen",
  "Nordmazedonien",
  "Norwegen",
  "Oman",
  "Österreich",
  "Osttimor",
  "Pakistan",
  "Palau",
  "Panama",
  "Papua-Neuguinea",
  "Paraguay",
  "Peru",
  "Philippinen",
  "Pitcairn",
  "Polen",
  "Portugal",
  "Puerto Rico",
  "Taiwan",
  "Republik Kongo",
  "Moldavien",
  "Réunion",
  "Ruanda",
  "Rumänien",
  "Russland",
  "Salomonen",
  "Sambia",
  "Samoa",
  "San Marino",
  "São Tomé und Príncipe",
  "Saudi-Arabien",
  "Schweden",
  "Schweiz",
  "Senegal",
  "Serbien",
  "Seychellen",
  "Sierra Leone",
  "Simbabwe",
  "Singapur",
  "Slovenien",
  "Slowakei",
  "Somalia",
  "Spanien",
  "Sri Lanka",
  "St. Kitts und Nevis",
  "St. Lucia",
  "St. Vincent und die Grenadinen",
  "Staat Palästina",
  "Südafrika",
  "Sudan",
  "Südkorea",
  "Südsudan",
  "Surinam",
  "Swasiland",
  "Syrien",
  "Tadschikistan",
  "Tansania",
  "Thailand",
  "Tibet",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad und Tobago",
  "Tschad",
  "Tschechien",
  "Tunesien",
  "Türkei",
  "Turkmenistan",
  "Turks- und Caicosinseln",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "Ungarn",
  "Uruguay",
  "Usbekistan",
  "Vanuatu",
  "Vatikanstadt",
  "Venezuela",
  "Vereinigte Arabische Emirate",
  "Vereinigte Staaten",
  "Vereinigtes Königreich",
  "Vietnam",
  "Wallis und Futuna",
  "Weihnachtsinsel",
  "Weißrussland",
  "Westsahara",
  "Zentralafrikanische Republik",
  "Zypern"
];

const countries = []

countryList.forEach(country => {
  countries.push({
    name: country,
    value: country
  })
});

export default countries;
