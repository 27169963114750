export default [
  {
    name: "Frau", 
    value: "female"
  }, 
  {
    name: "Herr", 
    value: "male"
  }
]
