export default [
  {
    title: "1. Gegenstand", 
    paragraph: [
     "a. Die SCB Eishockey AG betreibt mit “MySCB” eine Plattform zur Erfassung sowie Einchecken von Gästen an Heimspielen des SC Berns.",
     "b. Gegenstand dieser AGB ist die Nutzung von “MySCB” durch den Saisonkartenhalter und den Gast. Die Zustimmung der AGBs erfolgt durch Nutzen der Dienstleistung; Spielbesucher stimmen ausdrücklich zu."
    ]
  },
  {
    title: "2. Leistungen der SCB Eishockey AG", 
    paragraph: [
     "a. Die SCB Eishockey AG kann den Betrieb des Tools jederzeit einstellen."
    ]
  },
  {
    title: "3a. Registrierung / Herausgabe der Daten | Saisonkartenhalter", 
    paragraph: [
     "a. Der Saisonkartenhalter registriert sich einmalig auf der Plattform.",
     "b. Auf seinem Profil kann er seine Saisonkarten verwalten.",
     "c. Pro Spiel kann er sich selbst oder einen Gast das Ticket aufs Mobile senden."
    ]
  }, 
  {
    title: "3b. Registrierung / Check-in / Herausgabe der Daten | Gast",
    paragraph: [
      "a. Der Saisonkartenhalter sendet das Spielticket über MySCB an den Gast.",
      "b. Der Gast erhält eine SMS mit einem Link, auf welchem er aufgefordert wird seine Kontaktdaten anzugeben. Danach erhält er sein personalisiertes Ticket.",
      "c. Der Gast wird durch das Scannen des QR-Codes, somit ist klar ersichtlich welche Spielbesucher das Heimspiel tatsächlich besucht haben und auf welchem Sitz sie das Spiel verfolgt haben.",
      "d. CheckIn-Daten werden nach 14 Tagen gelöscht. Es sei den, der Gast hat zugestimmt"
    ]
  },
  {
    title: "4. Haftung",
    paragraph: [
      "a. Bei Verletzungen ihrer eigenen vertraglichen Pflichten aus diesen AGB haftet die SCB Eishockey AG gegenüber dem Gast oder Betrieb unbeschränkt für von der SCB Eishockey AG durch rechtswidrige Absicht oder grobe Fahrlässigkeit verursachte direkte und nachgewiesene Schäden.",
      "b. Für leichte Fahrlässigkeit sowie für indirekte Schäden oder Folgeschäden wird die Haftung ausdrücklich ausgeschlossen. Folgeschäden sind insbesondere entgangener Gewinn, Reputationsschäden und Datenverlust in Folge vorübergehender Beeinträchtigungen oder Unterbrüchen der Verfügbarkeit der Leistungen der SCB Eishockey AG sowie infolge Ausfalls der Registrierung, Übermittlungsfehler, nicht rechtzeitige Zustellung von Mails. Ausgeschlossen ist auch jegliche Haftung der SCB Eishockey AG für Inhalte auf Websites von Veranstaltern und anderen Dritten, die auf die Website der SCB Eishockey AG verweisen oder auf welche die Website der SCB Eishockey AG verweisen.",
    ]
  },
  {
    title: "5. Geistiges Eigentum",
    paragraph: [
      "a. Das Tool sowie die gesamten über das Tool zugänglichen Inhalte der SCB Eishockey AG (nachfolgend 'Inhalte') sind urheberrechtlich geschützt und gehören, soweit nicht anders bestimmt, ausschliesslich und umfassend der SCB Eishockey AG. Das (vollständige oder teilweise) Reproduzieren, Verbreiten, Übermitteln (elektronisch oder mit anderen Mitteln), Modifizieren, Verknüpfen oder Benutzen der Inhalte für öffentliche oder kommerzielle Zwecke ist ohne vorherige schriftliche Zustimmung der SCB Eishockey AG untersagt."
    ]
  },
  {
    title: "6. Datenschutz",
    paragraph: [
      "a. Wir verwenden Personendaten zum Zweck der Bereitstellung funktionsfähiger Websites und zur Erbringung unserer Inhalte und Leistungen. Die SCB Eishockey AG behandelt Personendaten vertrauensvoll, sorgfältig und zweckbestimmt. Dabei halten wir uns an die massgeblichen Vorgaben des anwendbaren Datenschutzrechts",
      "b. Wenn Sie unsere Websites besuchen, werden durch den auf Ihrem Computer oder Mobilgerät zum Einsatz kommenden Browser automatisch Informationen an den Server unserer Websites gesendet. Diese Informationen werden temporär in einem sog. Logfile gespeichert.",
      "c. Wir nutzen diese Protokolldaten ohne Zuordnung zu Ihrer Person oder sonstiger Profilerstellung für statistische Auswertungen zum Zweck des Betriebs, der Sicherheit und der Optimierung unserer Websites, aber auch zur anonymen Erfassung der Anzahl der Besucher auf unseren Websites (Traffic) sowie zum Umfang und zur Art der Nutzung unserer Website und Dienste. Aufgrund dieser Informationen können wir Datenverkehr analysieren, Fehler suchen und beheben und unsere Dienste verbessern.",
      "d. Hierin liegt auch unser berechtigtes Interesse an dieser Datennutzung.",
      "e. Wir behalten uns vor, die Protokolldaten nachträglich zu überprüfen, wenn aufgrund konkreter Anhaltspunkte der berechtigte Verdacht einer rechtswidrigen Nutzung besteht. IP-Adressen speichern wir für einen begrenzten Zeitraum in den Logfiles, wenn dies für Sicherheitszwecke erforderlich oder für die Leistungserbringung oder die Abrechnung einer Leistung nötig ist, z. B. wenn Sie eines unserer Angebote nutzen. Ausserdem speichern wir als Teil Ihres Accounts das Datum Ihres letzten Besuchs (z. B. bei Registrierung, Login, Klicken von Links etc.).",
      "f. Wir bearbeiten Personendaten, die wir zur Erfüllung unserer vertraglichen Pflichten Ihnen gegenüber benötigen, etwa Vorname, Name, Adresse, Lieferadresse, E-Mail-Adresse, Telefonnummer.",
      "g. Die Rechtsgrundlage für die Bearbeitung dieser Daten ist die Erfüllung des Vertrages mit Ihnen.",
      "h. Sie haben die folgenden Rechte bezüglich Ihrer Personendaten. Möchten Sie diese Rechte geltend machen, so richten Sie Ihre Anfrage bitte per E-Mail an support@scb.ch oder per Post an SCB Eishockey AG / Mingerstrasse 12b/ 3014 Bern",
      "i. Auskunft über Ihre von uns bearbeiteten Personendaten zu verlangen. Insbesondere können Sie Auskunft über die Bearbeitungszwecke, die Kategorie der Personendaten, die Kategorie von Empfängern, gegenüber denen Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer, das Bestehen eines Rechts auf Berichtigung; Löschung, Einschränkung der Verarbeitung oder Widerspruch, das Bestehen eines Beschwerderechts, die Herkunft ihrer Daten, sofern diese nicht bei uns erhoben wurden, sowie über das Bestehen einer automatisierten Entscheidungsfindung einschliesslich Profiling und aussagekräftigen Informationen zu deren Einzelheiten verlangen;",
      "ii. Unverzüglich die Berichtigung unrichtiger oder Vervollständigung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen;",
      "iii. Die Löschung Ihrer bei uns gespeicherten Personendaten zu verlangen, soweit nicht die Bearbeitung zur Ausübung des Rechts auf freie Meinungsäusserung und Information, zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist;",
      "iv. Die Einschränkung der Bearbeitung Ihrer Personendaten zu verlangen, soweit (i) die Richtigkeit der Daten von Ihnen bestritten wird, (ii) die Bearbeitung unrechtmässig ist, Sie aber die Löschung ablehnen, (iii) wir die Daten nicht mehr benötigen, Sie jedoch diese zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen, oder (iv) Sie Widerspruch gegen die Bearbeitung eingelegt haben, solange noch nicht feststeht, ob die berechtigten Gründe unseres Unternehmens gegenüber den Ihren überwiegen;",
      "v. Ihre Personendaten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesebaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen;",
      "vi. Ihre einmal erteilte Einwilligung jederzeit gegenüber uns zu widerrufen. Dies hat zur Folge, dass wir die Datenbearbeitung, die auf dieser Einwilligung beruhte, für die Zukunft nicht mehr fortführen dürfen;",
      "vii. Sich bei einer Aufsichtsbehörde zu beschweren. In der Regel können Sie sich hierfür an die Aufsichtsbehörde Ihres üblichen Aufenthaltsortes oder Arbeitsplatzes oder unseres Unternehmenssitzes wenden.",
      "i. Wir sind um die Sicherheit Ihrer Daten im Rahmen der geltenden Datenschutzgesetze und technischen Möglichkeiten maximal bemüht. Ihre persönlichen Daten werden bei uns verschlüsselt übertragen. Wir nutzen SSL (Secure Socket Layer), weisen jedoch darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich. Zur Sicherung Ihrer Daten unterhalten wir technische und organisatorische Sicherungsmassnahmen, die wir immer wieder dem Stand der Technik anpassen. Wir gewährleisten ausserdem nicht, dass unser Angebot zu bestimmten Zeiten zur Verfügung steht; Störungen, Unterbrechungen oder Ausfälle können nicht ausgeschlossen werden. Die von uns verwendeten Server werden regelmässig sorgfältig gesichert und. Der Serverstandort liegt in der Schweiz.",
      "j. Wir verwenden Ihre Personendaten nur im Umfang dieser AGB. Ausgenommen davon sind die Zurverfügungstellung an Behörden / Betreiber, falls es ein Corona-Fall in einem Betrieb gibt."
    ]
  },
  {
    title: "7. Schlussbestimmungen",
    paragraph: [
      "a. Auf diese AGB und allfällige aus oder im Zusammenhang mit dem Verhältnis zwischen der SCB Eishockey AG und dem Spielbesucher entstehende Streitigkeiten ist ausschliesslich Schweizer Recht anwendbar, unter Ausschluss der kollisionsrechtlichen Bestimmungen und der Bestimmungen des UN-Kaufrechts (CISG).",
      "b. Ausschliesslicher Gerichtsstand für alle Streitigkeiten zwischen der SCB Eishockey AG und dem Saisonkartenhalter und den Gast ist Bern."
    ]
  }
]