import React, {
  forwardRef,
  useState,
  useRef,
  useEffect,
  useImperativeHandle,
  Fragment,
} from 'react';
import Field from './Field';

/**
 * Ticket code input component
 * @param {Object} [props]
 * @param {Function} [props.onChange] - Callback method when value is changed
 * @component
 */
export default forwardRef(({ onChange = () => {}, ...restProps }, ref) => {
  const [code, setCode] = useState('');
  const [inputFocued, setInputFocued] = useState(false);
  const input = useRef();

  useImperativeHandle(ref, () => ({ clear: () => setCode('') }));

  useEffect(() => {
    onChange(
      code
        .split('')
        .map((char, index) => index !== 0 && !(index % 3) ? `-${char}` : char)
        .join('')
    );
  }, [code, onChange]);

  const fieldValue = place => code.split('')[place - 1] || '';

  const handleFiledClick = index => () => {
    setCode(code.substring(0, index));

    input.current.focus();
  };

  const handleChange = ({ target: { value } }) => setCode(
    value
      .toUpperCase()
      .replace(/[^a-z0-9]/gi,'')
      .substring(0, 9)
  );

  const handleKeyUp = ({ target }) => target.setSelectionRange(target.value.length, target.value.length);

  return (
    <div
    className="TicketCodeInput"
    {...restProps}
  >
      <input
        ref={input}
        className="TicketCodeInput-Input"
        type="text"
        value={code}
        maxLength={11}
        onFocus={() => setInputFocued(true)}
        onBlur={() => setInputFocued(false)}
        onChange={handleChange}
        onKeyUp={handleKeyUp}
      />

      {[...Array(9)].map((_, index) => (
        <Fragment key={index}>
          {index !== 0 && !(index % 3) && <span className="TicketCodeInput-separator" />}

          <Field
            active={inputFocued && code.length === index}
            onClick={handleFiledClick(index)}
          >
            {fieldValue(index + 1)}
          </Field>
        </Fragment>
      ))}
    </div>
  );
});
