import React, {
  useState,
  useEffect,
  forwardRef
} from 'react';
import Select from '../Select';
import {FaTimes} from 'react-icons/fa';

// Months in German
const months = [
  'Januar',
  'Februar',
  'März',
  'April',
  'Mai',
  'Juni',
  'Juli',
  'August',
  'September',
  'Oktober',
  'November',
  'Dezember',
];

const years = [];
for (let y = 1900; y <= (new Date()).getFullYear(); y++) years.push(y);

/**
 * No date
 * @type {Object}
 */
const noDate = {
  day: undefined,
  month: undefined,
  year: undefined,
};

/**
 * Separate data from date
 * @param {string} date - Date in ISO format
 * @return {Object}
 */
const getDate = date => {
  if (!date) {
    return noDate;
  }

  const newDate = new Date(date);

  return newDate instanceof Date && !isNaN(newDate) ? {
      day: newDate.getDate(),
      month: newDate.getMonth() + 1,
      year: newDate.getFullYear(),
    } : noDate
};

/**
 * Check if date is empty
 * @param {string} date - Date in ISO format
 * @return {boolean}
 */
const isDateEmpty = date => !date.day && !date.month && !date.year;

/**
 * Add zero if it is necessary
 * @param {number | string} no - Number
 */
const noWithZero = (no = '') => ('0' + no.toString()).slice(-2);

/**
 * DatePicker component
 * @param {Object} [props]
 * @param {string} [props.valueDate] - Date in ISO format
 * @param {'' | 'light'} [props.theme] - Theme
 * @param {string} [props.label] - Label text
 * @param {boolean} [props.error] - DatePicker with error
 * @param {boolean} [props.marked] - Different style
 * @param {Function} [props.onChangeDate] - Callback method when date is changed
 * @component
 */
export default forwardRef(({ valueDate = '', theme = '', label = '', error = false, marked = false, onChangeDate = () => {}, ...resetProps }, ref) => {
  const [date, setDate] = useState(valueDate ? getDate(valueDate) : noDate);

  useEffect(() => { setDate(valueDate ? getDate(valueDate) : noDate) }, [valueDate]);

  const handleChange = ({ target }) => {
    const data = {
      day: date.day,
      month: date.month,
      year: date.year,
      [target.name]: target.value,
    };

    setDate(data);

    if (!data.day || !data.month || !data.year) return;

    const newDate = new Date(`${data.year}-${noWithZero(data.month)}-${noWithZero(data.day)}`);

    if (newDate instanceof Date && !isNaN(newDate)) {
      onChangeDate(newDate.toISOString());
    }
  };

  const handleClearClick = () => valueDate ? onChangeDate('') : setDate(noDate);

  return (
    <div
      className={[
        'input-wrap',
        theme === 'light' ? 'light' : '',
      ].join(' ')}
    >

      {!!label && 
        <div className="label">
          <span>{label} </span> {!!marked && <span style={{fontSize: 10, opacity: 0.35, letterSpacing: 0.3}}> (Privat)</span>} 
        </div>
      } 

      <div
        className={[
          'DatePicker',
          error && !valueDate ? 'error' : '',
        ].join(' ')}
      >
        <Select
          theme={theme}
          name="day"
          options={[]}
          value={date.day || ''}
          onOptionChange={handleChange}
        >
          <option hidden value="">Tag</option>

          {[...Array(31)].map((_, index) => (
            <option
              key={index + 1}
              value={index + 1}
            >
              {index + 1}
            </option>
          ))}
        </Select>

        <Select
          theme={theme}
          name="month"
          options={[]}
          value={date.month || ''}
          onOptionChange={handleChange}
        >
          <option hidden value="">Monat</option>

          {months.map((month, index) => <option key={month} value={index + 1}>{month}</option>)}
        </Select>

        <Select
          theme={theme}
          name="year"
          options={[]}
          value={date.year || ''}
          onOptionChange={handleChange}
        >
          <option hidden value="">Jahr</option>

          {years.map(year => <option key={year} value={year}>{year}</option>)}
        </Select>

        <FaTimes
          className={isDateEmpty(date) ? 'DatePicker-clearbutton hide' : 'DatePicker-clearbutton'}
          onClick={handleClearClick}
        />
 
      </div>
      <input
        style={{display: "none", }}
        ref={ref}
        value={valueDate}
        {...resetProps}
        readOnly
      />
      <span className="error-msg">{error && !valueDate && <p>Daten fehlen</p>}</span>
    </div>
  );
});
