import React from 'react';
import UserForm from './../components/UserForm';
import {
  useHistory,
  Redirect,
} from 'react-router-dom';
import { useMutation } from 'react-query';
import Page from './../components/Page';
import Container from './../components/Container';
import HeaderTitle from './../components/HeaderTitle';
import UserLibrary from '../libraries/User';
import api from '../api';
import redirectConfig from '../config/redirect';
import Link from './../components/Link';

/**
 * Registration/signup page
 * @component
 */
export default () => {
  const history = useHistory();
  const [mutate, { error, isLoading, isError }] = useMutation(api.auth.register);

  const onSubmitHandler = async user => {
    delete user.data_protection;

    const data = await mutate(user);

    if (data && data.data) {
      UserLibrary.setEmailVerified(false);

      UserLibrary.setDataCompleted(true);

      UserLibrary.SetIsPasswordSet(true);

      // UserLibrary.setBusinessAccount(!!data.roles?.includes('BUSINESS') || !!data.roles?.includes('BUSINESS_ADMIN'));

      // UserLibrary.setBusinessAdmin(!!data.roles?.includes('BUSINESS_ADMIN'));

      // Temporary enable all business accounts editing
      UserLibrary.setBusinessAccount(!!data.roles?.includes('BUSINESS'));
      UserLibrary.setBusinessAdmin(!!data.roles?.includes('BUSINESS'));

      UserLibrary.update({
        firstName: data.data.first_name || '',
        lastName: data.data.last_name || '',
      });

      // Check if __REDIRECT_TO_GAMES__ flag is set, then redirect if it is ture
      if (window.__REDIRECT_TO_GAMES__) {
        window.location.replace(redirectConfig.games);
      } else {
        history.push('/profile');
      }
    }
  };

  // If user is logged in, redirect to profile page
  if (UserLibrary.isLoggedIn()) {
    return <Redirect to="/profile" />;
  }

  return (
    <Page>
      <HeaderTitle>MySCB</HeaderTitle>

      <Container>
        <UserForm
          hasEmail
          hasRepeatEmail
          mandatory={["gender", "first_name", "last_name", "street", "zip", "city", "country", "birthday", "email", "email_repeat", "phone", "password", "data_protection"]}
          title="Registrieren"
          paragraph="Fülle deine Daten im Registrierungsformular aus und aktiviere deine Saisonkarte(n). Oder nimm einfach nur teil an den MySCB-Aktivitäten und hol dir tolle Preise!"
          hasGender={true}
          hasFanname={true}
          hasPassword={true}
          hasDataProtectionConfirmation
          hasMarketing
          confirmLabel="Absenden"
          onConfirm={onSubmitHandler}
          isError={isError}
          errorMsg={isError && error?.data?.message}
          isLoading={isLoading}
        />

        <div className="add-row">
          <Link
            text="Haben Sie bereits ein Account bei MySCB?"
            theme="dark"
            pathname="/login"
          />
        </div>
      </Container>
    </Page>
  );
};
