import moment from 'moment';
import 'moment/locale/de';

/**
 * Date & time library
 */
export default class Time {
  /**
   * Date from string
   * @param {string} dateTime - ISO date format
   * @returns {string}
   */
  static date(dateTime) {
    return moment(dateTime).format('DD.MM.YYYY');
  }

  /**
   * Time from string
   * @param {string} dateTime - ISO date format
   * @returns {string}
   */
  static time(dateTime) {
    return moment(dateTime).format('HH:mm');
  }

  /**
   * Day and date from string
   * @param {string} dateTime - ISO date format
   * @returns {string}
   */
  static dayAndDate(dateTime) {
    return moment(dateTime).format('dddd, DD.MM.YYYY');
  }
}
