import React from 'react';
import Item from './Item';

/**
 * ProfileList component
 * @param {Object} [props]
 * @param {*} [props.children] - Item component(s)
 * @component
 */
function ProfiletList({ children }) {
  return (
    <div className="ContactList">
      {children}
    </div>
  );
};

ProfiletList.Item = Item;

export default ProfiletList;
