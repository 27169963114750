import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Title from '../Title';
import Input from '../Input';
import InputImage from '../InputImage';
import Button from '../Button';
import ErrorMessages from '../ErrorMessages';
import requsetErrorMessage from '../../libraries/requsetErrorMessage';
import emptyStringToNull from '../../libraries/emptyStringToNull';
import filterObjectLibrary from '../../libraries/filterObject';

/**
 * Filter data from user object
 * @param {Object} user - Contact data
 */
const dataForServer = user => filterObjectLibrary(user, [
  'name',
  'address',
  'location',
  'zip',
  'postbox',
  'logo',
]);

/**
 * Business form component
 * @param {Object} [props]
 * @param {Object} [props.user] - Profile data
 * @param {string} [props.title] - Title text
 * @param {string} [props.confirmLabel] - Text in confirm button
 * @param {string} [props.cancelLabel] - Text in cancel button
 * @param {Function} [props.onConfirm] - Callback method when confirm button is clicked
 * @param {Function} [props.onCancel] - Callback method when cancel button is clicked
 * @param {boolen} [props.isError] - Error happened on request
 * @param {Array} [props.errorMsg] - Error messages on request returned with error
 * @param {boolen} [props.isLoading] - Spinner activ on button after request is sent
 * @component
 */
export default ({
  user = {},
  title = '',
  confirmLabel = '',
  cancelLabel= '',
  onConfirm = () => {},
  onCancel = () => {},
  isError = false,
  errorMsg = null, // Initial value must be null
  isLoading = false,
}) => {
  const { register, handleSubmit, setValue, errors } = useForm({ criteriaMode: 'all' });
  const [userData, setUserData] = useState(dataForServer(user));
  const [errorText, setErrorText] = useState(errorMsg);
  const [loading, setLoading] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => void(window.scrollTo(0, 0)), []);

  useEffect(() => void(setErrorText(errorMsg)), [errorMsg]);

  const handleChange = event => {
    setErrorText(null);

    setUserData({
      ...userData,
      [event.target.name]: event.target.value,
    });
  };

  const handleImageUpload = url => {
    setValue('logo', url);

    setUserData({ ...userData, logo: url });
  };

  const handleSuccessSubmit = data => onConfirm(emptyStringToNull({ ...userData, ...data }));

  return (
    <div className="form-container">
      {!!title && <Title>{title}</Title>}

      <form onSubmit={handleSubmit(handleSuccessSubmit)}>
        <div className="row-wrap">
          <Input
            fullwidth
            ref={register({ required: true })}
            label="Name *"
            name="name"
            defaultValue={user.name}
            error={errors.name}
            onChange={handleChange}
          />

          <Input
            ref={register}
            label="Strasse & Nummer"
            name="address"
            defaultValue={user.address}
            error={errors.address}
            onChange={handleChange}
          />

          <Input
            ref={register}
            label="Ort"
            name="location"
            defaultValue={user.location}
            error={errors.location}
            onChange={handleChange}
          />

          <Input
            ref={register}
            label="PLZ"
            name="zip"
            defaultValue={user.zip}
            error={errors.zip}
            onChange={handleChange}
          />

          <Input
            ref={register}
            label="Postfach"
            name="postbox"
            defaultValue={user.postbox}
            error={errors.postbox}
            onChange={handleChange}
          />

          <InputImage
            fullwidth
            ref={register({ required: false })}
            label="Logo"
            defaultValue={user.logo}
            error={errors.logo}
            name="logo"
            onChange={handleChange}
            onImageUpload={handleImageUpload}
            onLoading={setLoading}
          />
        </div>

        {isError && <ErrorMessages messages={requsetErrorMessage(errorText)} />}

        <div className={!!confirmLabel && !!cancelLabel ? 'row' : ''}>
          {!!confirmLabel && (
            <Button
              fullwidth={!cancelLabel}
              type="submit"
              loading={isLoading}
              disabled={isLoading || loading}
            >
              {confirmLabel}
            </Button>
          )}

          {!!cancelLabel && (
            <Button
              disabled={isLoading}
              onClick={onCancel}
              theme="gray"
            >
              {cancelLabel}
            </Button>
          )}
        </div>
      </form>
    </div>
  );
};
