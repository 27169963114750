import React from 'react';

/**
 * Games component for SquarePasses
 * @component
 */
export default () => (
  <div className="SquarePasses-Item">
    <div className="SquarePasses-Item-body middle">
      {/* <h2 className="games"></h2> */}
    </div>
  </div>
);
