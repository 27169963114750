import React, { useRef } from 'react';
import { useMutation } from 'react-query';
import Input from '../Input';
import Button from '../Button';
import ErrorMessages from '../ErrorMessages';
import requsetErrorMessage from '../../libraries/requsetErrorMessage';
import api from '../../api';

/**
 * AnonymousLink component
 * @param {Object} [props]
 * @param {string} [props.gameId] - ID of game
 * @param {string} [props.passId] - ID of pass
 * @param {string} [props.link] - Anonymous link
 * @param {boolean} [props.disabled] - Disable component
 * @param {Funtion} [props.onCreateLink] - Callback method when link/token is created
 * @component
 */
export default ({ gameId = '', passId = '', link = '', disabled = false, onCreateLink = () => {} }) => {
  const input = useRef();
  const [createAnonymousLink, { data, isLoading, isError, error }] = useMutation(api.access.createAnonymousLink, {
    onSuccess: data => onCreateLink(data),
  });

  const handleCopyClick = () => {
    input.current.select();
    input.current.setSelectionRange(0, 99999); /*For mobile devices*/

    document.execCommand('copy');
  };

  return(
    <div className="AnonymousLink">
      {(!!link || !!data) && (
        <>
          <Input
            readOnly
            disabled={disabled}
            ref={input}
            type="text"
            name="link"
            value={link}
            theme="light"
          />

          {!disabled && (
            <span
              className="AnonymousLink-copy"
              onClick={handleCopyClick}
            >
              Kopieren
            </span>
          )}
        </>
      )}

      {isError && <ErrorMessages messages={requsetErrorMessage(error.data.message)} />}

      {!link && !data && (
        <Button
          loading={isLoading}
          disabled={isLoading || disabled}
          onClick={() => createAnonymousLink({ game_id: gameId, pass_id: passId })}
        >
          Einladungslink
        </Button>
      )}
    </div>
  );
};
