import React, { useEffect, useState} from 'react';
import {
  useHistory,
  Redirect,
} from 'react-router-dom';
import { useMutation } from 'react-query';
import Page from '../components/Page';
import Container from '../components/Container';
import HeaderTitle from '../components/HeaderTitle';
import Title from '../components/Title';
import Paragraph from '../components/Paragraph';
import UserLibrary from '../libraries/User';
import api from '../api';
import Button from './../components/Button';
import ErrorMessages from './../components/ErrorMessages';
import requsetErrorMessage from '../libraries/requsetErrorMessage';

/**
 * Verify email page
 * @component
 */
export default () => {
  const [resendUsed, setResendUsed] = useState(false)
  const history = useHistory();

  // Mutation is used here to avoid caching
  const [user] = useMutation(api.user.data, {
    onSuccess({ data }) {
      if (data.has_verified_email) {
        UserLibrary.setEmailVerified(!!data.has_verified_email);

        history.push('/');
      }
    },
  });

  const [resendEmail, { isLoading, error, isError }] = useMutation(api.user.resendEmail, {
    onSuccess() {
      setResendUsed(true)
    },
  });

  useEffect(() => { user() }, [user]);

  // If user is not logged in, redirect to login page
  if (UserLibrary.isLoggedIn() && UserLibrary.isEmailVerified()) {
    return <Redirect to="/" />;
  } else if (!UserLibrary.isLoggedIn()) {
    return <Redirect to="/login" />;
  }

  return (
    <Page>
      <HeaderTitle>MySCB</HeaderTitle>

      <Container>
        <Container.SideMenu />

        <Title>Bestätige deine Email-Adresse</Title>

        <Paragraph>
          Wir haben dir eine Email geschickt mit einem Bestätigungslink. Bitte schaue in deiner Inbox und klicke auf den Link, um deine Email-Adresse, welche du im Profil hinterlegt hast, zu bestätigen. Sobald die Email-Adresse verifiziert ist, kannst du hier deine Saisonkarte(n) aktivieren.
        </Paragraph>
        {!resendUsed ? 
          <>
            <Button
              loading={isLoading}
              disabled={isLoading}
              onClick={resendEmail}
            >
              E-Mail nochmals senden
            </Button> 
            {isError && !!error?.data?.message && <ErrorMessages messages={requsetErrorMessage(error.data.message)} />}
          </>
          :
          <Paragraph>Überprüfe deinen Posteingang!</Paragraph>
        }
      </Container>
    </Page>
  );
}
