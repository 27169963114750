import React, {
  useState,
  useRef,
} from 'react';
import {
  useHistory,
  Redirect,
} from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import ReCAPTCHA from 'react-google-recaptcha';
import Page from './../components/Page';
import Container from './../components/Container';
import HeaderTitle from './../components/HeaderTitle';
import Title from './../components/Title';
import Input from '../components/Input';
import Button from '../components/Button';
import ErrorMessages from '../components/ErrorMessages';
import Link from './../components/Link';
import UserLibrary from '../libraries/User';
import requsetErrorMessage from '../libraries/requsetErrorMessage';
import recaptchaConfig from '../config/recaptcha';
import api from '../api';

/**
 * Login page
 * @component
 */
export default () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorLoginMessage, SetErrorLoginMessage] = useState('');
  const reCAPTCHA = useRef();
  const reCaptchaToken = useRef('');
  const history = useHistory();
  const { register, handleSubmit, errors } = useForm({ criteriaMode: 'all' });
  const [login, { isLoading, isError }] = useMutation(api.auth.login, {
    onSuccess({ data }) {
      UserLibrary.setEmailVerified(!!data.has_verified_email);

      UserLibrary.setDataCompleted(!!data.isCompleted);

      UserLibrary.SetIsPasswordSet(!!data.isPasswordSet);

      // UserLibrary.setBusinessAccount(!!data.roles?.includes('BUSINESS') || !!data.roles?.includes('BUSINESS_ADMIN'));

      // UserLibrary.setBusinessAdmin(!!data.roles?.includes('BUSINESS_ADMIN'));

      // Temporary enable all business accounts editing
      UserLibrary.setBusinessAccount(!!data.roles?.includes('BUSINESS'));
      UserLibrary.setBusinessAdmin(!!data.roles?.includes('BUSINESS'));



      UserLibrary.update({
        firstName: data.first_name || '',
        lastName: data.last_name || '',
      });

      history.push(data.has_verified_email
        ? (data.isCompleted ? '/' : '/profile')
        : '/verifyEmail'
      );
    },
    onError(error) {
      SetErrorLoginMessage(requsetErrorMessage(error && error.data && !!error.data.message && error.data.message));
    },
  });

  const handleLogin = async () => {
    if (!reCaptchaToken.current) {
      reCaptchaToken.current = await reCAPTCHA.current.executeAsync();
    }

    login({ email, password, reCaptchaToken: reCaptchaToken.current });
  };

  if (UserLibrary.isLoggedIn()) {
    return <Redirect to="/profile" />; // If user is logged in, redirect to profile page
  } else if (UserLibrary.get('verifiedEmail') === false) {
    return <Redirect to="/verifyEmail" />; // If user is logged but emial is not verified, redirect to email verify page
  }

  const handleInputChange = ({ target }) => {
    if (target.name === 'email') {
      setEmail(target.value);
    } else if (target.name === 'password') {
      setPassword(target.value);
    }

    SetErrorLoginMessage('');
  };

  return (
    <Page>
      <HeaderTitle>MySCB</HeaderTitle>

      <Container>
        <div className="form-container">
          <Title>Login</Title>

          <form onSubmit={handleSubmit(handleLogin)}>
            <Input
              label="Email-Adresse"
              type="email"
              name="email"
              error={errors.email}
              ref={register({ required: true })}
              onChange={handleInputChange}
            />

            <Input
              ispassword
              label="Passwort"
              name="password"
              error={errors.password}
              ref={register({ required: true })}
              onChange={handleInputChange}
            />

            {isError && !!errorLoginMessage && <ErrorMessages messages={errorLoginMessage} />}

            <div className="row">
              <Button
                type="submit"
                loading={isLoading}
                disabled={isLoading}
              >
                Login
              </Button>
            </div>
          </form>

          <Link
            text="Passwort vergessen?"
            theme="dark"
            pathname="/resetPassword"
          />

          <Link
            text="Sie haben noch keinen Account?"
            theme="dark"
            pathname="/registration"
          />
        </div>
      </Container>

      <ReCAPTCHA
        ref={reCAPTCHA}
        sitekey={recaptchaConfig.sitekey}
        size="invisible"
        theme="dark"
        hl="de"
      />
    </Page>
  );
};
