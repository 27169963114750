import React from 'react';
import Modal from '../../components/Modal';
import Title from '../../components/Title';
import Paragraph from '../../components/Paragraph';
import Button from '../../components/Button';
import dataProtection from '../../utils/dataProtection'

/**
 * DataProtection modal
 * @param {Object} [props]
 * @param {boolean} [props.isOpen] - Show/hide modal
 * @param {'small' | 'default' | 'big'} [props.size] - Modal width size
 * @param {Function} [props.onRequestClose] - Callback method when modal should be closed
 * @component
 */
export default ({
  isOpen = false,
  size = 'default',
  onRequestClose = () => {},
}) => {
  return(
    <Modal
      isOpen={isOpen}
      title= "Datenschutz - bestimmungen"
      size={size}
      onRequestClose={onRequestClose}
    >

      {dataProtection.map((segment, i) => {
        return (
          <React.Fragment key={i}>
            <Title small theme="light">
              {segment.title}
            </Title>
            {segment.paragraph.map((paragraph, i) => {
              return (
                <Paragraph key={i} theme="light">
                  {paragraph}
                </Paragraph>
              )
            })}
          </React.Fragment>
        )
      })}

      <Button
        onClick={onRequestClose}
      >
        OK
      </Button>

    </Modal>
  );
};