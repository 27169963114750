import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import FacebookLogin from 'react-facebook-login';
import { useMutation } from 'react-query';
import Page from '../../components/Page';
import Container from '../../components/Container';
import HeaderTitle from '../../components/HeaderTitle';
import Title from '../../components/Title';
import Paragraph from '../../components/Paragraph';
import Button from '../../components/Button';
import ErrorMessages from '../../components/ErrorMessages';
import UserLibrary from '../../libraries/User';
import requsetErrorMessage from '../../libraries/requsetErrorMessage';
import facebookConfig from '../../config/facebook';
import redirectConfig from '../../config/redirect';
import api from '../../api';

export default () => {
  const history = useHistory();
  const [errorLoginMessage, SetErrorLoginMessage] = useState('');
  const [loginWithFacebook, { isLoading, isError }] = useMutation(api.auth.loginWithFacebook, {
    onSuccess({ data }) {
      // No need to verify email when logging in with Facebook
      UserLibrary.setEmailVerified(true);

      UserLibrary.setDataCompleted(!!data.isCompleted);

      UserLibrary.SetIsPasswordSet(!!data.isPasswordSet);

      // UserLibrary.setBusinessAccount(!!data.roles?.includes('BUSINESS') || !!data.roles?.includes('BUSINESS_ADMIN'));

      // UserLibrary.setBusinessAdmin(!!data.roles?.includes('BUSINESS_ADMIN'));

      // Temporary enable all business accounts editing
      UserLibrary.setBusinessAccount(!!data.roles?.includes('BUSINESS'));
      UserLibrary.setBusinessAdmin(!!data.roles?.includes('BUSINESS'));

      UserLibrary.update({
        firstName: data.first_name || '',
        lastName: data.last_name || '',
      });

      // Check if __REDIRECT_TO_GAMES__ flag is set, then redirect if it is ture
      if (window.__REDIRECT_TO_GAMES__) {
        window.location.replace(redirectConfig.games);
      } else {
        history.push(data.isCompleted ? '/' : '/profile');
      }
    },
    onError(error) {
      SetErrorLoginMessage(requsetErrorMessage(error && error.data && !!error.data.message && error.data.message));
    },
  });

  const handleFBCallback = response => {
    console.log('handleFBCallback', response);

    if (response?.accessToken) {
      loginWithFacebook({ accessToken: response.accessToken });
    }
  };

  return (
    <Page>
      <HeaderTitle />

      <Container>
        <div className="welcome-screen">
          <Title>
            Jetzt bei MySCB registrieren
          </Title>

          <Paragraph>
            Jetzt bei MySCB registrieren und deine Saisonkarte aktivieren! Auch wenn du keine Saisonkarte hast, kannst du dich bei uns registrieren und mit MySCB profitieren.
          </Paragraph>

          {isError && !!errorLoginMessage && <ErrorMessages messages={errorLoginMessage} />}

          <Button
            disabled={isLoading}
            onClick={() => history.push('/registration')}
          >
            Jetzt registrieren
          </Button>

          <Button
            disabled={isLoading}
            onClick={() => history.push('/login')}
          >
            Jetzt einloggen
          </Button>

          <div style={{ marginTop: 20 }}>
            <FacebookLogin
              isDisabled={isLoading}
              appId={facebookConfig.appId}
              textButton="Mit Facebook einloggen" // Login with Facebbok
              icon="fa-facebook"
              scope="public_profile,email"
              callback={handleFBCallback}
            />
          </div>
        </div>
      </Container>
    </Page>
  )
};
