import React from 'react';
import Item from './Item';

/**
 * ContactList component
 * @param {Object} [props]
 * @param {*} [props.children] - Item component(s)
 * @component
 */
function ContactList({ children }) {
  return (
    <div className="ContactList">
      {children}
    </div>
  );
};

ContactList.Item = Item;

export default ContactList;