import React, { forwardRef } from 'react';

/**
 * Button component
 * @param {Object} [props]
 * @param {string} [props.text]
 * @param {boolean} [props.initialValue] - Default checked or not
 * @param {boolean} [props.error] - checkbox with error
 * @param {string} [props.linkText] - Text in link
 * @param {string} [props.textAfter] - Text after the link text
 * @param {Function} [props.onLinkClick] - Callback on link click
 * @param {'' | 'light'} [props.theme] - Theme
 * 
 * @component
 */
export default forwardRef(({ text ="", initialValue = false, error = false, linkText = "", textAfter ="", onLinkClick, theme = "",  ...resetProps }, ref) => (
  <div className={[
          'checkbox-holder',
          theme === 'light' ? 'light' : '',
        ].join(' ')
      }
  >
    <label className="checkbox">
      <input
        type="checkbox"
        className={error ? 'error' : ''}
        ref={ref}
        defaultChecked = {initialValue}
        {...resetProps}
      >
      </input>
      <span></span>
    </label>
    <div className="checkbox-text">
      <span>{text}</span>
      {linkText && 
        <>
          <span onClick={onLinkClick} className="link">{linkText}</span>
          {textAfter && <span>{textAfter}</span>}
        </>
      }
    </div>
  </div>
));
