import API from '../libraries/API';

/**
 * Access update
 * @param {[query, params]} args
 * @param {Object} query
 * @param {string} query.game_id - ID of game
 * @param {string} query.pass_id - ID of pass
 * @param {Object} params
 * @param {string} [params.first_name] - First name
 * @param {string} [params.last_name] - Last name
 * @param {string} [params.street] - Street address
 * @param {string} [params.zip] - ZIP code
 * @param {string} [params.city] - City name
 * @param {string} [params.country] - Country name
 * @param {string} params.phone - Phone number
 * @param {string} [params.birthday] - Date of birth
 * @param {boolean} [params.accept_marketing] - Who knows what this is
 */
const access = params => API.put('access',params);

/**
 * Accesses update
 * @param {Object} params
 * @param {string} params.pass_id - ID of pass
 * @param {string} [params.first_name] - First name
 * @param {string} [params.last_name] - Last name
 * @param {string} [params.street] - Street address
 * @param {string} [params.zip] - ZIP code
 * @param {string} [params.city] - City name
 * @param {string} [params.country] - Country name
 * @param {string} params.phone - Phone number
 * @param {string} [params.birthday] - Date of birth
 * @param {boolean} [params.accept_marketing] - Who knows what this is
 */
const accesses = params => API.post('access', params);

/**
 * Access QR code
 * @param {Object} params
 * @param {string} params.access_token - Access token code
 */
const accessQRCode = ({ access_token }) => API.get(`access/${access_token}/qrcode`);

/**
 * Edit/update eTicket data
 * @param {Object} params
 * @param {string} params.access_token - Access token code
 */
const updateETicket = ({ access_token, ...restParams }) => API.patch(`access/${access_token}/eticket`, restParams);

/**
 * eTicket data
 * @param {Object} params
 * @param {string} params.access_token - Access token code
 */
 const eTicket = (_, access_token, queryObj) => {
   let url = `access/${access_token}/eticket`
   let { own_ticket } = queryObj
   if(own_ticket) {
    return API.get(url, queryObj)
   } else {
      return API.get(url)
   }
  };

/**
 * Create anonymous link
 * @param {Object} params
 * @param {string} params.game_id - ID of game
 * @param {string} params.pass_id - ID of pass
 */
const createAnonymousLink = params => API.post('access/anonymous', params);

/**
 * Invite data
 * @param {Object} params
 * @param {string} params.anonymous_token - Anonymous token code
 */
const invite = (_, anonymous_token) => API.get(`access/anonymous/${anonymous_token}`);

/**
 * Update Invite data
 * @param {Object} params
 * @param {Object} params.phone - Phone number
 * @param {string} params.anonymous_token - Anonymous token code
 */
const updateInvite = ({ anonymous_token, phone }) => API.post(`access/anonymous/${anonymous_token}`, phone);

/**
 * Request for access (lottery)
 * @param {Object} params
 * @param {string} params.game_id - ID of game
 * @param {string} params.pass_id - ID of pass
 */
const accessRequest = params => API.post('access/request', params);

export default {
  accesses,
  access,
  accessQRCode,
  updateETicket,
  eTicket,
  createAnonymousLink,
  invite,
  updateInvite,
  accessRequest,
}
