import React from 'react';
import { useQuery, useMutation } from 'react-query';
import Page from '../components/Page';
import Container from '../components/Container';
import HeaderTitle from '../components/HeaderTitle';
import Loader from '../components/Loader';
import Title from '../components/Title';
import Button from '../components/Button';
import Paragraph from '../components/Paragraph';
import ErrorMessages from '../components/ErrorMessages';
import VoucherInfo from '../components/VoucherInfo';
import Time from '../libraries/Time';
import api from '../api';
import requsetErrorMessage from '../libraries/requsetErrorMessage';

/**
 * Reedeem voucher
 * @component
 */
export default ({ match }) => {
  const voucher = useQuery(['voucher', match.params?.voucher_code], api.voucher.voucher);
  const [redeemVoucher, { error, isLoading, isSuccess }] = useMutation(api.voucher.redeemVoucher);

  const handleButtonPress = () => redeemVoucher({ voucher_code: match.params.voucher_code });

  return (
    <Page>
      <HeaderTitle>MySCB</HeaderTitle>

      <Container>
        <Title>Gutschein einlösen?</Title>

        {voucher.isLoading && <Loader />}

        {voucher.isSuccess && voucher.data?.data && (
          <div style={{ marginBottom: 50 }}>
            <VoucherInfo
              style={{ marginBottom: 20 }}
              name={voucher.data.data.name}
              expireAt={voucher.data.data.expire_at}
              description={voucher.data.data.description}
              image={voucher.data.data.image_url}
              firstName={voucher.data.data.user.first_name}
              lastName={voucher.data.data.user.last_name}
            >
              {!isSuccess && (
                <Button
                  onClick={handleButtonPress}
                  loading={isLoading}
                  disabled={isLoading || !!voucher.data?.data.is_used}
                >
                  {voucher.data.data.is_used ? `Verwendet am ${Time.date(voucher.data.data.used_at)}` : 'Gutschein einlösen'}
                </Button>
              )}
           </VoucherInfo>

            {isSuccess && (
              <Paragraph>
                {/* TODO: Replace meesage here with some success message */}
                Sie haben diesen Gutschein erfolgreich eingelöst!
              </Paragraph>
            )}

            {error?.data?.message && <ErrorMessages messages={requsetErrorMessage(error.data.message)} />}

          </div>
        )}

        {voucher.error?.data?.message && <ErrorMessages messages={requsetErrorMessage(voucher.error.data.message)} />}
      </Container>
    </Page>
  )
}
