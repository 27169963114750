import React, { useState, useEffect } from "react";
import Logo from "./../../assets/images/scb-logo.png";
import { useLocation } from 'react-router-dom';
import headerConfig from '../../config/header';

/**
 * Check if Header should be visible or not
 * @param {string} pathname - URL pathname
 */
const isIgonredPage = pathname => !!headerConfig.ignorePages.find(page => !!pathname.includes(page));

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  const location = useLocation();
  const [hide, setHide] = useState(isIgonredPage(location.pathname));

  useEffect(() => {
    showMenu
      ? (document.querySelector("html").style.overflow = "hidden")
      : (document.querySelector("html").style.overflow = "auto");
  }, [showMenu]);

  // Check pages where Header should be ignored
  useEffect(() => { setHide(isIgonredPage(location.pathname)) }, [location]);

  return hide ? null : (
    <header className="SCB-navigation">
      <div className="container">
        <div className="header-meta">
          <a href="https://www.postfinancearena.ch/en/" target="_blank" rel="noopener noreferrer">PostFinance-Arena</a>
          <a href="https://www.scb.ch/fan-zone/fanarbeit" target="_blank" rel="noopener noreferrer">Fan-Zone</a>
          <a href="https://www.scbshop.ch/shop/java/shop/home" target="_blank" rel="noopener noreferrer">Fan-Shop</a>
          <span>MySCB</span>
        </div>
        <div className="row">
          <div className="header-left">
            <a href="https://www.scb.ch/news/news" target="_blank" rel="noopener noreferrer">News</a>
            <a href="https://www.scb.ch/club/unser-scb" target="_blank" rel="noopener noreferrer">Club</a>
            <a href="https://www.scb.ch/team/mannschaft/das-team" target="_blank" rel="noopener noreferrer">Team</a>
          </div>
          <div className="header-logo">
            <a href="https://www.scb.ch/"  target="_blank" rel="noopener noreferrer">
              <img src={Logo} alt="SCB-logo"></img>
            </a>
          </div>
          <div className="header-right">
            <a href="https://www.scb.ch/future/scb-future" target="_blank" rel="noopener noreferrer">Future</a>
            <a href="https://www.scb.ch/business/business/uebersicht" target="_blank" rel="noopener noreferrer">Business</a>
            <a href="https://www.scb.ch/gastronomie/gastronomie-im-stadion" target="_blank" rel="noopener noreferrer">Gastronomie</a>
          </div>
          <div className="header-right-mob">
            <button className={`button_toggle ${showMenu ? "close" : ""}`}  onClick={() => setShowMenu(!showMenu)}>

              <div className='button_toggle_line'></div>
              <div className='button_toggle_line'></div>
              <div className='button_toggle_line'></div>
            </button>
          </div>
        </div>
        <div className={`navigation-mob ${showMenu ? "show" : ""}`}>
          <div className="nav-mob">
            <div className="nav-mob-main">
              <a href="https://www.scb.ch/news/news" target="_blank" rel="noopener noreferrer">News</a>
              <a href="https://www.scb.ch/club/unser-scb" target="_blank" rel="noopener noreferrer">Club</a>
              <a href="https://www.scb.ch/team/mannschaft/das-team" target="_blank" rel="noopener noreferrer">Team</a>
              <a href="https://www.scb.ch/future/scb-future" target="_blank" rel="noopener noreferrer">Future</a>
              <a href="https://www.scb.ch/business/business/uebersicht" target="_blank" rel="noopener noreferrer">Business</a>
              <a href="https://www.scb.ch/gastronomie/gastronomie-im-stadion" target="_blank" rel="noopener noreferrer">Gastronomie</a>
            </div>
            <div className="nav-mob-meta">
              <div className="row">
                <a href="https://www.postfinancearena.ch/en/" target="_blank" rel="noopener noreferrer">PostFinance-Arena</a>
              </div>
              <div className="row">
                <a href="https://www.scb.ch/fan-zone/fanarbeit" target="_blank" rel="noopener noreferrer">Fan-Zone</a>
                <a href="https://www.scbshop.ch/shop/java/shop/home" target="_blank" rel="noopener noreferrer">Fan-Shop</a>
                <span  onClick={() => setShowMenu(false)}>MySCB</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header;
