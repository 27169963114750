import React from 'react';
import editIcon from '../../assets/images/icons/Edit_contact.svg';

/**
 * Edit component for SquarePasses
 * @param {Object} [props]
 * @param {boolean} [props.disabled] - Disable component
 * @param {Function} [props.onClick] - Callback method when item is clicked
 * @component
 */
export default ({ disabled = false, onClick = () => {} }) => (
  <div className={`SquarePasses-Item edit ${disabled ? 'disabled' : ''}`} onClick={!disabled ? onClick : () => {}}>
    <div className="SquarePasses-Item-body middle">
      <img className="editcontacticon" src={editIcon} alt="Edit"/>

      {/* <h4 className="editlabel">Matchticket kann<br />in Kürze weitergeleitet<br />werden</h4> */}
      <h4 className="editlabel">Matchbesucher<br />definieren</h4>

    </div>
  </div>
);
