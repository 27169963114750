import React from 'react';
import TimeLibary from '../../libraries/Time';

/**
 * Club component for SquarePasses
 * @param {Object} [props]
 * @param {string} [props.clubLogo] - URL of club's logo
 * @param {string} [props.clubName] - Name of club
 * @param {string | null} [props.dateTime] - Date and time of game
 * @component
 */
export default ({ clubLogo = '', clubName = '', dateTime = null }) => (
  <div className="SquarePasses-Item">
    <div className="SquarePasses-Item-body">
      <img className="clublogo" src={clubLogo} alt="Logo" />

      <h3>{clubName}</h3>

      {!!dateTime ? (
        <>
          <span className="date">{TimeLibary.date(dateTime)},</span>

          <span className="date">{TimeLibary.time(dateTime)} Uhr</span>
        </>
      ) : <span className="date"><em>Verschoben</em></span>}

      <span className="stadium">PostFinance Arena<br />Bern</span>
    </div>
  </div>
);
