import React from 'react';

/**
 * Switcher component
 * @param {Object} [props]
 * @param {boolean} [props.active] - Selected button
 * @param {*} [props.icon] - Image (icon) source
 * @param {Function} [props.onClick] - Callback method when button is clicked
 * @component
 */
export default ({ active = false, icon = '', onClick = () => {}, ...restProps }) => (
  <div
    className={active ? 'Switcher-Button active' : 'Switcher-Button'}
    onClick={onClick}
    {...restProps}
  >
    {!!icon && <img className="Switcher-Button-icon" src={icon} alt="Icon button"/>}
  </div>
);
