import React from 'react';
import { FaCheck } from 'react-icons/fa';
import TimeLibrary from '../../libraries/Time';

/**
 * TicketChecked component
 * @param {Object} [props]
 * @param {Object} [props.guest] - User's data
 * @component
 */
export default ({ guest }) => {
  return(
    <div className="ticket-checked-info">
      {guest.qr_code && (
        <div className="qr-code">
          <img src={guest.qr_code} alt="qr code"/>
        </div>
      )}

      {guest.access_token && (
        <a href={`/api/access/${guest.access_token}/image.png`} target='blank' className='access-token'>
          {guest.access_token}
        </a>
      )}

      {guest.is_scanned ? (
        <div className="ticket-used">
          <div className="used yes">
            <FaCheck/> <span>CHECK-IN ERFOLGREICH</span>
          </div>

          {!!guest.scanned_at && (
            <p>
              Check-in:

              <span> {TimeLibrary.date(guest.scanned_at)},</span>

              <span> {TimeLibrary.time(guest.scanned_at)} Uhr</span>
            </p>
          )}
        </div>
      ) :!!guest.gameHasEnded && (
        <div className="no-info" style={{ marginBottom: 0, width: '100%' }}>
          <p>{guest.game.date ? 'Dieses Spiel ist schon zu Ende' : 'Spiel wurde verschoben - Neuer Termin ausstehend'}</p>
        </div>
      )}
    </div>
  )
};
