import React from 'react';
import Pass from './Pass';
import Club from './Club';
import NoMatch from './NoMatch';
import Games from './Games';
import Edit from './Edit';
import User from './User';
import Row from './Row';
import Invite from './Invite';
import Lottery from './Lottery';

/**
 * SquarePasses component
 * @param {Object} [props]
 * @param {*} [props.children]
 * @component
 */
const SquarePasses = ({ children = null }) => {
  return (
    <div className="SquarePasses">
      {children}
    </div>
  );
};

SquarePasses.Pass = Pass;
SquarePasses.Club = Club;
SquarePasses.NoMatch = NoMatch;
SquarePasses.Games = Games;
SquarePasses.Edit = Edit;
SquarePasses.User = User;
SquarePasses.Row = Row;
SquarePasses.Invite = Invite;
SquarePasses.Lottery = Lottery;

export default SquarePasses;
