import API from '../libraries/API';

/**
 * Get profile by ID
 * @param {Object} params
 * @param {string} params.profile_id - ID of profile
 */
const profile = ({ profile_id }) => API.get(`businessprofile/${profile_id}`);

/**
 * Update/edit profile by ID
 * @param {Object} params
 * @param {string} params.id - ID of profile
 * @param {string} params.avatar - URL of image file
 * @param {string} params.first_name - First name
 * @param {string} params.last_name - Last name
 * @param {string} params.role - Role
 * @param {string} params.gender - Gender
 * @param {string} params.phone - Phone number
 * @param {string} params.phone_mobile - Mobile phone number
 * @param {string} params.email - Email address
 */
const updateProfile = ({ id, ...restParams }) => API.patch(`user/me/business-profile/${id}`, { ...restParams });

/**
 * Add/create new profile
 * @param {Object} params
 * @param {string} params.avatar - URL of image file
 * @param {string} params.first_name - First name
 * @param {string} params.last_name - Last name
 * @param {string} params.role - Role
 * @param {string} params.gender - Gender
 * @param {string} params.phone - Phone number
 * @param {string} params.phone_mobile - Mobile phone number
 * @param {string} params.email - Email address
 */
const createProfile = params => API.post(`user/me/business-profile`, params);

/**
 * Delete profile by ID
 * @param {Object} params
 * @param {string} params.profile_id - ID of profile
 */
const deleteProfile = ({ profile_id }) => API.delete(`user/me/business-profile/${profile_id}`);

export default {
  profile,
  updateProfile,
  createProfile,
  deleteProfile,
}
