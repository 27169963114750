import React from 'react';
import Button from './Button';

/**
 * Switcher component
 * @param {Object} [props]
 * @param {'left' | 'center' | 'right'} [props.align] - Align buttons
 * @param {*} [props.children] - React component(s)
 * @component
 */
const Switcher = ({ align = 'left', children, ...restProps }) => (
  <div
    className={[
      'Switcher',
      align === 'center' ? 'center' : '',
      align === 'right' ? 'right' : '',
    ].join(' ')}
    {...restProps}
  >
    {children}
  </div>
);

Switcher.Button = Button;

export default Switcher;
